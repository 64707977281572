import units from './units/units.store';

const state = {
  loggers: [],
  logger: {
    PeNumber: null,
  },
  instrumentId: null,
};

const actions = {
};

const mutations = {
  instrumentId(state, payload) {
    state.instrumentId = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    units,
  },
};
