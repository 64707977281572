<template>
  <div style="height: calc(100vh - 17px); width: 100%">
    <!--
    <GateWaySettings
      :selected-sensor="selectedSensor"
      :selected-vector="selectedVector"
      :selected-tab="TabValue"
      @update-sensor="RefreshSensorTableau($event)"
      @update-vector="RefreshVectorTableau($event)"
    />
    -->
    <v-toolbar dense>
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Gateway.DispatchDataFiles')"
        :icon-name="'mdi-cloud-upload'"
        @click="toolbarDispatchDataFiles"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Gateway.BuildAutoConfigScript')"
        :icon-name="'mdi-robot'"
        @click="toolbarBuildAutoConfig"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Gateway.Refresh')"
        :icon-name="'mdi-refresh'"
        @click="RefreshTables"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Gateway.GenerateToken')"
        :icon-name="'mdi-key-variant'"
        @click="toolbarGenerateToken"
      />
      <div
        v-if="$auth.roles.includes('Web.Gateway.Manage')"
      >
        <toolbar-button-with-tooltip
          :tooltip-text="$t('Devices.Gateway.MQTTCredentials')"
          :icon-name="'mdi-account-key'"
          @click="toolbarGetMqttCredentials"
        />
      </div>
    </v-toolbar>
    <v-tabs
      centered
    >
      <v-tab @click="tabChange('Sensor')">
        {{ $t('Devices.Gateway.SensorMapping') }}
      </v-tab>
      <v-tab @click="tabChange('Vector')">
        {{ $t('Devices.Gateway.VectorMapping') }}
      </v-tab>
      <v-tab @click="tabChange('Attribute')">
        {{ $t('Devices.Gateway.Attributes') }}
      </v-tab>
      <v-tab @click="tabChange('Feed')">
        {{ $t('Devices.Gateway.Feeds') }}
      </v-tab>
    </v-tabs>
    <sensor-ingress-list
      v-if="TabValue == 'Sensor'"
      style="height: calc(100% - 175px)"
      :refresh="Refresh"
    />
    <vector-ingress-list
      v-if="TabValue == 'Vector'"
      style="height: calc(100% - 175px)"
      :refresh="Refresh"
    />
    <attribute-list
      v-if="TabValue == 'Attribute'"
      style="height: calc(100% - 175px)"
      :refresh="Refresh"
    />
    <feed-list
      v-if="TabValue == 'Feed'"
      style="height: calc(100% - 175px)"
      :refresh="Refresh"
    />
    <v-dialog
      v-model="ShowBuildAutoConfigDialog"
      persistent
      transition="dialog-bottom-transition"
      content-class="editor-dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title
          v-if="DialogModeDispatch"
        >
          {{ $t('Devices.Gateway.DispatchDataFiles') }}
        </v-card-title>
        <v-card-title
          v-if="!DialogModeDispatch"
        >
          {{ $t('CreateAutoConfigFile') }}
        </v-card-title>
        <v-card-subtitle>
          &nbsp;
        </v-card-subtitle>
        <v-card-text>
          <v-select
            v-model="SelectedFileParser"
            :items="FileParserTypes"
            :disabled="SelectedFileParser !== null && DialogModeDispatch"
            :label="$t('Devices.Gateway.FileParser')"
            item-text="Label"
            item-value="Value"
            dense
          />
        </v-card-text>
        <v-card-text>
          <v-file-input
            v-model="files"
            color="deep-purple accent-4"
            counter
            :label="$t('Devices.Gateway.FileInput')"
            multiple
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
            <template #selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="files.length === 0 || SelectedFileParser === null"
            @click.stop="onAcceptBuildAutoConfig"
          >
            {{ $t('DialogModeDispatch') ?
              $t('Devices.Gateway.Dispatch') : $t('Devices.Gateway.Create') }}
          </v-btn>
          <v-btn
            text
            @click.stop="ShowBuildAutoConfigDialog=false"
          >
            {{ $t('Devices.Gateway.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ShowTokenValidator"
      persistent
      transition="dialog-bottom-transition"
      content-class="editor-dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Validate Token</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-text-field
              v-model="tokenLabel"
              outlined
              @input="updateTokenLabel"
            />
          </div>
        </v-card-text>
        <v-card-text>
          <div>
            <v-text
              v-if="tokenValid !== 'Devices.Gateway.null'"
            />
            {{ $t('Devices.Gateway.'.concat(tokenValid)) }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="!tokenLabel.trim()"
            @click.stop="validateToken(tokenLabel)"
          >
            {{ $t('Devices.Gateway.Validate') }}
          </v-btn>
          <v-btn
            text
            @click.stop="closeTokenDialog"
          >
            {{ $t('Devices.Gateway.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ShowMQTTValidator"
      persistent
      transition="dialog-bottom-transition"
      content-class="editor-dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('Devices.Gateway.MQTTCredentials') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="password"
            :label="$t('WindServer.Sites.SiteConfigurations.Password')"
            readonly
          />
          <v-list class="overflow-list">
            <v-subheader>
              {{ $t('Devices.Gateway.ValidClientsID') }}
            </v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="client in validClients"
                :key="client"
                dense
                @click="copyText(client)"
              >
                {{ client }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="closeMQTTDialog"
          >
            {{ $t('Devices.Gateway.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fileDownload from 'js-file-download';
import importal from '@/api/importal';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import AttributeList from './AttributeList.vue';
import FeedList from './FeedList.vue';
// import GateWaySettings from './GateWaySettingPanel.vue';
import SensorIngressList from './SensorIngressList.vue';
import VectorIngressList from './VectorIngressList.vue';

export default {
  components: {
    // GateWaySettings,
    SensorIngressList,
    VectorIngressList,
    AttributeList,
    FeedList,
    ToolbarButtonWithTooltip,
  },
  data: () => ({
    TabValue: 'Sensor',
    Refresh: 0,
    ShowBuildAutoConfigDialog: false,
    ShowTokenValidator: false,
    ShowMQTTValidator: false,
    DialogModeDispatch: false,
    GatewayAttributes: null,
    files: [],
    SelectedFileParser: null,
    tokenInfo: null,
    tokenValid: null,
    password: '',
    validClients: [],
    FileParserTypes: [
      { Label: 'USEP Survey Monitoring', Value: 'TextFileParserFactory.AtriumToronto' },
      { Label: 'BMP North East Elev.', Value: 'TextFileParserFactory.BmpNorthEastElevation' },
      { Label: 'CampbellSci TOA5/TOACI1', Value: 'TextFileParserFactory.CampbellSciDatFileAutoVec' },
      { Label: 'Compacted custom readings', Value: 'TextFileParserFactory.CompactedCustomReadings' },
      { Label: 'Digitilt RPP Inclinometer', Value: 'TextFileParserFactory.DigitiltInclinometerDataFile' },
      { Label: 'DurhamGeo DigiPro DUX Inclinometer', Value: 'TextFileParserFactory.DurhamGeoInclinometerDataFile' },
      { Label: 'Environnement Canada', Value: 'TextFileParserFactory.CanadaWeatherClientCsv' },
      { Label: 'France Government - Weather', Value: 'TextFileParserFactory.EnvFranceWeatherClientCsv' },
      { Label: 'Geokon Inclinometer', Value: 'TextFileParserFactory.GeokonInclinometerDataFile' },
      { Label: 'Geomos Total Station', Value: 'TextFileParserFactory.GeomosTotalStationDataFile' },
      { Label: 'GeoSense Inclinometer', Value: 'TextFileParserFactory.GeoSenseInclinometerDataFile' },
      { Label: 'Glötzl Inclinometer', Value: 'TextFileParserFactory.GlotzlInclinometerDataFile' },
      { Label: 'In Situ VuLink (CSV)', Value: 'TextFileParserFactory.VuLink' },
      { Label: 'Instantel Thor CSV', Value: 'TextFileParserFactory.Instantel' },
      { Label: 'Load Sensing', Value: 'TextFileParserFactory.LoadSensingDataFile' },
      { Label: 'MetOne AirPlus', Value: 'TextFileParserFactory.MetOneAirPlus' },
      { Label: 'RST Inclinometer', Value: 'TextFileParserFactory.RSTInclinometerDataFile' },
      { Label: 'RST Data Logger', Value: 'TextFileParserFactory.RSTDtLoggerDataFile' },
      { Label: 'Sisgeo H-Level', Value: 'TextFileParserFactory.SisgeoHLevel' },
      { Label: 'Symphonie Pro (NRG)', Value: 'TextFileParserFactory.SymphonieProNRG' },
      { Label: 'Syscom ASCII', Value: 'TextFileParserFactory.SyscomStandard' },
      { Label: 'Syscom ASCII (With event vectors)', Value: 'TextFileParserFactory.SyscomWithEventVectors' },
      { Label: 'Windserver Export (CSV)', Value: 'TextFileParserFactory.WindServerExport' },
      { Label: 'Trimble T4D (CSV)', Value: 'TextFileParserFactory.TrimbleT4D' },
      { Label: 'CampbellSci TOA5/TOACI1 (Auto-Vector disabled)', Value: 'TextFileParserFactory.CampbellSciDatFile' },
      { Label: 'Geoverra AMTS', Value: 'TextFileParserFactory.GeoverraTotalStationDataFile' },
      { Label: 'KiWiS (CSV)', Value: 'TextFileParserFactory.Kiwis' },
    ],
  }),
  computed: {
    tokenLabel() {
      if (this.tokenInfo) {
        return this.tokenInfo;
      }
      return this.$t('Devices.Gateway.EnterToken');
    },
  },
  mounted() {
    this.init();
  },
  created() {
  },
  methods: {
    updateTokenLabel(value) {
      this.tokenInfo = value;
    },
    async init() {
      await importal.get(`DevGatewayList?DataGatewayId=${this.$route.params.id}`)
        .then((result) => result.data)
        .then((data) => {
          this.$store.commit('app/pageTitle', { key: 'Gateway: {Name}', payload: data[0] });
        });
      await importal.get(`DevGatewayAttributeList?DataGatewayId=${this.$route.params.id}`)
        .then((result) => result.data)
        .then((data) => {
          this.GatewayAttributes = data;
        });
    },
    toolbarDispatchDataFiles() {
      this.DialogModeDispatch = true;
      this.SelectedFileParser = this.GatewayAttributes.find((x) => x.Name === 'FORMAT')?.ValueStr ?? null;
      this.files = [];
      this.ShowBuildAutoConfigDialog = true;
    },
    toolbarBuildAutoConfig() {
      this.DialogModeDispatch = false;
      this.SelectedFileParser = this.GatewayAttributes.find((x) => x.Name === 'FORMAT')?.ValueStr ?? null;
      this.files = [];
      this.ShowBuildAutoConfigDialog = true;
    },
    async onAcceptBuildAutoConfig() {
      if (this.DialogModeDispatch) {
        this.$store.commit('app/pleaseWait', true);
        const bodyFormData = new FormData();
        bodyFormData.append('file_format', this.SelectedFileParser);
        bodyFormData.append('DataGatewayId', this.$route.params.id);
        for (let i = 0; i < this.files.length; i += 1) {
          bodyFormData.append('files[]', this.files[i]);
        }

        await importal.post('DevGatewayDispatchFiles', bodyFormData)
          .then(() => {
            this.ShowBuildAutoConfigDialog = false;
            this.RefreshTables();
            this.$store.commit('app/pleaseWait', false);
          })
          .catch(() => {
            this.ShowBuildAutoConfigDialog = false;
            this.RefreshTables();
            this.$store.commit('app/pleaseWait', false);
          });
      } else {
        this.$store.commit('app/pleaseWait', true);
        const bodyFormData = new FormData();
        bodyFormData.append('file_format', this.SelectedFileParser);
        for (let i = 0; i < this.files.length; i += 1) {
          bodyFormData.append('files[]', this.files[i]);
        }

        await importal.post('DevGatewayBuildAutoConfig', bodyFormData)
          .then((resp) => {
            fileDownload(resp.data, 'script.auto', 'text/auto');
            this.ShowBuildAutoConfigDialog = false;
            this.$store.commit('app/pleaseWait', false);
          })
          .catch(() => {
            this.ShowBuildAutoConfigDialog = false;
            this.$store.commit('app/pleaseWait', false);
          });
      }
    },
    async validateToken(textInput) {
      if (textInput) {
        this.$store.commit('app/pleaseWait', false);
        const apiParams = {
          DataGatewayId: this.$route.params.id,
          Token: textInput,
        };
        await importal.post('DevGatewaySecurityValidateToken', apiParams)
          .then((resp) => {
            if (resp.data && resp.data.IsValid) {
              this.tokenValid = this.$t('TokenValid');
            } else {
              this.tokenValid = this.$t('TokenInvalid');
            }
          })
          .catch(() => {
            this.tokenValid = this.$t('TokenInvalid');
          });
      }
    },
    async getTokenText() {
      try {
        const apiParams = {
          DataGatewayId: this.$route.params.id,
          ValidDays: this.GatewayAttributes[0].ValidDays !== undefined ? this.GatewayAttributes[0].ValidDays : null,
        };
        const response = await importal.post('DevGatewaySecurityGetToken', apiParams);
        this.tokenInfo = response.data.Token;
      } catch (error) {
        // console.error('Error fetching token information:', error);
      }
    },
    toolbarGenerateToken() {
      this.ShowTokenValidator = true;
      this.getTokenText();
    },
    async toolbarGetMqttCredentials() {
      this.ShowMQTTValidator = true;
      const result = await (await importal.get(`DevGatewaySecurityGetMqttCredentials?DataGatewayId=${this.$route.params.id}`)).data;
      if (result) {
        this.password = result.Password;
        this.validClients = result.ClientIds;
      }
    },
    RefreshTables() {
      this.Refresh += 1;
    },
    tabChange(changeto) {
      if (changeto === 'Sensor') {
        this.TabValue = 'Sensor';
      }
      if (changeto === 'Vector') {
        this.TabValue = 'Vector';
      }
      if (changeto === 'Attribute') {
        this.TabValue = 'Attribute';
      }
      if (changeto === 'Feed') {
        this.TabValue = 'Feed';
      }
    },
    closeTokenDialog() {
      this.ShowTokenValidator = false;
      this.tokenValid = null;
    },
    closeMQTTDialog() {
      this.ShowMQTTValidator = false;
      this.mqttValid = null;
    },
    async copyText(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        // eslint-disable-next-line no-alert
        alert('Copied');
      } catch ($e) {
        // eslint-disable-next-line no-alert
        alert('Cannot copy');
      }
    },
  },
};
</script>

<style lang="scss">
.overflow-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
