<template>
  <div style="width: 100%; height: 100%;">
    <AutoFormPopup
      v-model="popup.Show"
      :model="popup.Model"
      :schema="popup.Schema"
      @on-accept="onPopupAccept"
    />
    <ConfirmDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      @on-accept="dialog.acceptCallback()"
    />
    <v-toolbar
      v-if="showToolbar || toolbarFields.length"
      dense
      :height="toolbarFields.length ? 50 : undefined"
    >
      <v-toolbar-items
        v-for="(item, index) in toolbarContent"
        :key="index"
      >
        <toolbar-button-with-tooltip
          :tooltip-text="item.tooltip"
          :icon-name="item.icon"
          :disabled="!item.enabled"
          @click="item.click"
        />
      </v-toolbar-items>
      <v-toolbar-items
          v-for="(item, index) in toolbarFields"
          :key="`${item.label}_${index}`"
      >
        <search-text-field
          v-if="item.fieldType === fieldTypes.SearchTextField"
          :value="item.value"
          :label="item.label"
          :tooltip="item.tooltip"
          :disabled="item.disabled"
          :width="item.width"
          :on-searched="item.onSearched"
          class="mt-4"
        />
        <select-field
          v-else-if="item.fieldType === fieldTypes.SelectField"
          :value="item.value"
          :label="item.label"
          :items="item.items"
          :item-text="item.itemText"
          :disabled="item.disabled"
          :width="item.width"
          :multiple="item.multiple"
          :on-changed="item.onChanged"
          :on-clear="item.onClear"
          class="mt-4"
        />
        <select-with-search
          v-else-if="item.fieldType === fieldTypes.SelectWithSearch"
          :value="item.value"
          :search-value="item.searchValue"
          :label="item.label"
          :items="item.items"
          :item-text="item.itemText"
          :has-more-items="item.hasMoreItems"
          :error="item.error"
          :disabled="item.disabled"
          :width="item.width"
          :on-changed="item.onChanged"
          :on-searched="item.onSearched"
          :on-more="item.onMore"
          class="mt-2 mx-2"
        />
      </v-toolbar-items>
    </v-toolbar>
    <ag-grid-vue
      :style="{ width: '100%', height: gridSource.height || '100%' }"
      class="ag-theme-balham-dark"
      :column-defs="columnDefs"
      :row-data="rowData"
      :row-model-type="rowModelType"
      :datasource="dataSource"
      :default-col-def="defaultColDef"
      :suppress-row-click-selection="true"
      :row-selection="rowSelection"
      :locale-text="localeText"
      :pagination="pagination"
      accented-sort="true"
      @grid-ready="onGridReady"
      @cell-value-changed="onCellValueChanged"
      @selection-changed="onSelectionChanged"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import i18n from '@/plugins/i18n';
import agGridFr from '@/locales/agGridFr';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import AutoFormPopup from './AutoFormPopup.vue';
import DeleteButtonIdCellRenderer from './DeleteButtonIdCellRenderer.vue';
import InfoButtonStringCellRenderer from './InfoButtonStringCellRenderer.vue';
import IconButtonCellRenderer from './IconButtonCellRenderer.vue';
import EditButtonCellRenderer from './EditButtonCellRenderer.vue';
import ReadonlyDateTimeCellRenderer from './ReadonlyDateTimeCellRenderer.vue';
import EditableDateTimeCellRenderer from './EditableDateTimeCellRenderer.vue';
import UtcDateTimeCellRenderer from './UtcDateTimeCellRenderer.vue';
import NonNullObjectCountCellRenderer from './NonNullObjectCountCellRenderer.vue';
import ToolbarButtonWithTooltip from './ToolbarButtonWithTooltip.vue';
import UserButtonCellRenderer from './UserButtonCellRenderer.vue';
import SelectField from './SelectField.vue';
import SearchTextField from './SearchTextField.vue';
import SelectWithSearch from './SelectWithSearch.vue';
import FieldTypesEnum from './FieldTypesEnum';

export default {
  components: {
    AgGridVue,
    AutoFormPopup,
    // eslint-disable-next-line vue/no-unused-components
    DeleteButtonIdCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    IconButtonCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    InfoButtonStringCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    EditButtonCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    EditableDateTimeCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ReadonlyDateTimeCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    UtcDateTimeCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    NonNullObjectCountCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    UserButtonCellRenderer,
    ToolbarButtonWithTooltip,
    ConfirmDialog,
    SelectField,
    SelectWithSearch,
    SearchTextField,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
    rowDataInit: {
      type: Array,
      default: () => null,
    },
    gridSource: {
      type: Object,
      required: true,
    },
    showNewButton: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    /*
        [
          {
            tooltip: 'Text'
            icon: 'mdi-....'
            enabled: Boolean
            click: ()
          }
        ]
     */
    toolbarButtons: {
      type: Array,
      default: () => ([]),
      required: false,
    },
    toolbarFields: {
      type: Array,
      default: () => ([]),
      required: false,
    },
    enableSelection: {
      type: String, // 'single', 'multiple'
      default: () => undefined,
      required: false,
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    validatePopupValues: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['selection-changed', 'length-changed'],
  setup() {
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      popup: {
        Show: false,
        Model: {},
        Schema: {},
      },
      rowSelection: null,
      SingleSelection: false,
      localeText: null,
      dialog: {
        Message: '',
        NoCancel: false,
        Title: '',
        Show: false,
        acceptCallback: null,
      },
      fieldTypes: FieldTypesEnum,
    };
  },
  computed: {
    rowModelType() {
      if (this.gridSource.interface === 'infinite') {
        return this.gridSource.interface;
      }
      return undefined;
    },
    dataSource() {
      if (this.gridSource.interface === 'infinite') {
        return this.gridSource.dataSource;
      }
      return undefined;
    },
    hasNewButton() {
      return this.gridSource && this.gridSource.onNewItem && this.showNewButton;
    },
    defaultColDef() {
      return {
        resizable: true,
        headerCheckboxSelection: this.isSingleSelectionEnabled,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: this.isSelectionEnabled,
        filterParams: { newRowsAction: 'keep', buttons: ['reset', 'apply'] },
      };
    },
    showToolbar() {
      return this.toolbarContent.length > 0;
    },
    toolbarContent() {
      const tbar = [];
      if (this.hasNewButton) {
        tbar.push({
          tooltip: i18n.t('AddNew'),
          icon: 'mdi-plus',
          enabled: true,
          click: () => { this.onNewButtonClick(); },
        });
      }

      if (this.toolbarButtons.length > 0) {
        this.toolbarButtons.forEach((x) => { tbar.push(x); });
      }

      return tbar;
    },
  },
  watch: {
    async refresh() {
      await this.initColumns();
      await this.initRows();
    },
    rowDataInit() {
      if (this.rowDataInit !== null) {
        this.rowData = this.rowDataInit;
      }
    },
    rowData(newVal) {
      this.$emit('length-changed', newVal ? this.rowData.length : 0);
    },
  },
  created() {
    if (i18n.locale === 'fr') {
      this.localeText = agGridFr.locale_fr;
    }
    if (this.enableSelection !== undefined) {
      this.rowSelection = this.enableSelection;
      this.SingleSelection = true;
    }
    if (this.enableSelection === 'single') {
      this.SingleSelection = false;
    }
  },
  async beforeMount() {
    await this.initColumns();
    await this.initRows();
  },
  methods: {
    async initColumns() {
      this.columnDefs = await this.gridSource.columnDefinitions();
    },
    async initRows() {
      if (this.gridSource.interface !== 'infinite') {
        if (this.rowDataInit !== null) {
          this.rowData = this.rowDataInit;
        } else {
          this.rowData = null;
          if (await this.gridSource.dataSource) {
            await this.gridSource.dataSource().getRows({
              // eslint-disable-next-line no-unused-vars
              successCallback: (rows, lastRow) => {
                this.rowData = rows;
              },
              failCallback: () => {
              },
            });
          }
        }
      }
    },
    isSelectionEnabled(params) {
      return this.isFirstColumn(params) && this.enableSelection;
    },
    isSingleSelectionEnabled(params) {
      return this.isFirstColumn(params) && this.SingleSelection;
    },
    isFirstColumn(params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.$emit('grid-ready', params);
    },
    onCellValueChanged(event) {
      if (this.gridSource.onChange !== undefined
          && this.gridSource.onChange()[event.colDef.field] !== undefined) {
        this.gridSource.onChange()[event.colDef.field](
          event,
          // eslint-disable-next-line no-unused-vars
          (rows, lastRow) => { // successCallback
            this.rowData = rows;
          },
          () => {}, // failCallback
        );
      }
    },
    onSelectionChanged(event) {
      this.$emit('selection-changed', event);
    },
    async onNewButtonClick(data) {
      const d = await this.gridSource.newItemFormDefinition(data);
      if (d) {
        this.popup.Model = d.Model;
        this.popup.Schema = d.Schema;
        this.popup.Show = true;
      }
    },
    onPopupAccept(data) {
      let errorMessage = '';
      if (this.validatePopupValues) {
        errorMessage = this.gridSource.validateValues(data);
      }

      if (!errorMessage || errorMessage.length === 0) {
        this.gridSource.onNewItem(
          data,
          // eslint-disable-next-line no-unused-vars
          (rows, lastRow) => { // successCallback
            this.rowData = rows;
          },
          () => {}, // failCallback
        );
      } else {
        this.showWarning(errorMessage, () => this.onNewButtonClick(data));
      }
    },
    showWarning(message, callback) {
      this.dialog.Message = message;
      this.dialog.NoCancel = true;
      this.dialog.Title = i18n.t('Settings.Dialog.Warning');
      this.dialog.acceptCallback = callback;
      this.dialog.Show = true;
    },
  },
};
</script>

<style scoped>
.v-select ::v-deep .v-select__selections  input {
  display: none;
}
</style>
