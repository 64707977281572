import AlertList from '@/alert/Manage/MyAlertList.vue';
import AlertEdit from '@/alert/Manage/sensor/AlertEdit.vue';

export default [
  {
    path: '/alert/manage',
    name: 'Alert.Manage',
    component: AlertList,
  },
  {
    path: '/alert/manage/searchInstrument/:searchInstrument/searchSensor/:searchSensor',
    name: 'Alert.Manage.SearchInstrumentSensor',
    component: AlertList,
  },
  {
    path: '/alert/manage/searchInstrument/:searchInstrument',
    name: 'Alert.Manage.SearchInstrument',
    component: AlertList,
  },
  {
    path: '/alert/manage/searchSensor/:searchSensor',
    name: 'Alert.Manage.SearchSensor',
    component: AlertList,
  },
  {
    path: '/alert/manage/:id',
    name: 'Alert.ManageAlert',
    component: AlertEdit,
  },
];
