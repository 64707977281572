import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

function recomputeBounds(bounds, configData) {
  const config = JSON.parse(configData);
  if (config.aspect !== undefined) {
    const [[x1, y1], [x2, y2]] = bounds;
    return [[x1, y1 * config.aspect], [x2, y2 * config.aspect]];
  }
  return bounds;
}

async function groupBaseLayers({ dispatch }, items) {
  const images = await Promise.all(items
    .filter((x) => x.LayerType === 'ImageLayer')
    .map(async (x) => ({
      path: x.ImagePath,
      url: await dispatch('readAsDataUrl', { path: x.ImagePath, mimeType: x.ImageMimeType }),
    })));

  const groups = [];
  for (let i = 0; i < items.length; i += 1) {
    const x = items[i];
    const entry = groups.find((y) => y.Name === x.Group && y.CRS === x.CRS);
    if (entry !== null && entry !== undefined) {
      if (x.LayerType === 'ImageLayer') {
        entry.ImageOverlays.push({
          layerGroup: x.Name,
          baseLayerId: x.BaseLayerId,
          url: images.find((y) => y.path === x.ImagePath).url,
          bounds: recomputeBounds([[-500, -500], [500, 500]], x.JsonData),
          options: {
          },
        });
      }
    } else {
      const newEntry = {
        Name: x.Group,
        CRS: x.CRS,
        TileLayers: [],
        ImageOverlays: [],
        Options: JSON.parse(x.JsonData),
      };
      if (x.LayerType === 'ImageLayer') {
        newEntry.ImageOverlays.push({
          layerGroup: x.Name,
          baseLayerId: x.BaseLayerId,
          url: images.find((y) => y.path === x.ImagePath).url,
          bounds: recomputeBounds([[-500, -500], [500, 500]], x.JsonData),
          options: {
          },
        });
      }
      groups.push(newEntry);
    }
  }
  return groups;
}

const state = {
  baseLayers: [],
  selectedMapView: null,

  instruments: [],
};

const actions = {
  async listBaseLayers({ commit, dispatch }) {
    return withWait({ commit }, () => api.get('MapBaseLayerList'))
      .then((resp) => groupBaseLayers({ dispatch }, resp.data))
      .then((data) => commit('baseLayers', data));
  },
  async selectMapView({ commit }, payload) {
    commit('selectedMapView', payload);
  },
  async listInstruments({ commit }) {
    return withWait({ commit }, () => api.get('MapInstrumentList'))
      .then((resp) => commit('instruments', resp.data));
  },
  // eslint-disable-next-line no-unused-vars
  async readAsDataUrl({ commit }, { path, mimeType }) {
    const response = await api.get(
      `MapBaseLayerDownload?path=${encodeURIComponent(path)}`, {
        responseType: 'blob',
      },
    );
    const imageBlob = await response.data;
    this.sourceBlob = imageBlob.slice(0, imageBlob.size, mimeType);

    const rc = new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(this.sourceBlob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    return rc;
  },

};

const mutations = {
  baseLayers(state, payload) {
    state.baseLayers = payload;
  },
  selectedMapView(state, payload) {
    state.selectedMapView = payload;
  },
  instruments(state, payload) {
    state.instruments = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
