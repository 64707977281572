export default class DashboardStackWidgetBase {
  constructor(grid, widget, iface) {
    this.grid = grid;
    this.widget = widget;
    this.interface = iface;
    this.registeredOnClickListeners = [];
  }

  updateWidgetNode(widget) {
    this.widget = widget;
  }

  // Called when a widget is removed
  unmountWidget() {
    this.removeAllListeners();
  }

  editModeFlagChanged() {
    const widgetId = this.widget.DashboardsWidgetId;

    // Close menus
    const menu = document.getElementById(`widget-menu-${widgetId}`);
    if (menu) {
      menu.style.display = 'none';
    }

    this.updateToolbarVisibility(widgetId);
  }

  isToolbarVisible() {
    const widgetsMenuItem = document.querySelectorAll(`.widget-menu-item-viewmode[data-widgetid="${this.widget.DashboardsWidgetId}"]`);
    return this.interface.editModeFlag() || widgetsMenuItem.length > 0;
  }

  // eslint-disable-next-line class-methods-use-this
  selectedInstrumentsChanged() {}
  // async displayWidget(update = false) {}

  // resizeToContainer(gridstackNode) { }

  getGridStackElement() {
    const found = document.querySelectorAll(`[gs-id="${this.widget.DashboardsWidgetId}"]`);
    if (found.length > 0) {
      return found[0];
    }
    return null;
  }

  updateActiveWidget() {
    this.interface.updateActiveWidget(this.widget.DashboardsWidgetId);
  }

  createWidgetNode() {
    const editModeFlag = this.interface.editModeFlag();
    const update = !!this.getGridStackElement();

    let node = null;
    const loader = '<div class="loader"></div>';
    if (update) {
      node = {
        id: this.widget.DashboardsWidgetId,
        x: this.widget.AxisX,
        y: this.widget.AxisY,
        w: this.widget.AxisWidth,
        // Node height is stored in pixels but must be converted to multiple of cell height
        h: Math.ceil(this.widget.AxisHeight / this.interface.cellHeight()),
        content: loader,
        noResize: !editModeFlag,
        noMove: !editModeFlag,
      };
      this.grid.update(
        this.getGridStackElement(), node,
      );
    } else {
      node = {
        id: this.widget.DashboardsWidgetId,
        x: this.widget.AxisX,
        y: this.widget.AxisY,
        w: this.widget.AxisWidth,
        // Node height is stored in pixels but must be converted to multiple of cell height
        h: Math.ceil(this.widget.AxisHeight / this.interface.cellHeight()),
        content: loader,
        noResize: !editModeFlag,
        noMove: !editModeFlag,
      };
      this.grid.addWidget(node);
    }

    const handles = document.getElementsByClassName('ui-resizable-handle');
    for (let i = 0; i < handles.length; i += 1) {
      const el = handles[i];
      el.style['z-index'] = 200;
    }

    return node;
  }

  waitForWidgetNode(node) {
    const pollFn = () => {
      if (document.getElementById(node.id)) {
        // const e = document.getElementById(node.id);
        const els = this.grid.getGridItems().filter((x) => x.gridstackNode.id === node.id);
        if (els.length > 0) {
          this.resizeToContainer(els[0].gridstackNode);
        } else {
          setTimeout(pollFn, 100);
        }
      } else {
        setTimeout(pollFn, 100);
      }
    };
    setTimeout(pollFn, 100);
  }

  // Create the HTML of a menu item
  createWidgetMenuItem(action, title, isViewModeMenu = false) {
    const activationClass = isViewModeMenu ? 'widget-menu-item-viewmode' : 'widget-menu-item-editmode';
    return `<div tabindex="0" role="menuitem" data-widgetid="${this.widget.DashboardsWidgetId}" data-action="${action}"`
          + ` class="widget-menu-item ${activationClass} v-list-item v-list-item--link theme--dark" style="min-height: 24px;">`
          + `<div class="v-list-item__title">${title}</div>`
          + '</div>';
  }

  // Add a menu dynamically before the Delete action
  addWidgetMenuItem(action, title, isViewModeMenu = false) {
    const widgetId = this.widget.DashboardsWidgetId;
    const deleteMenuItem = document.querySelectorAll(`.widget-menu-item[data-widgetid="${widgetId}"][data-action="delete"]`);
    if (deleteMenuItem) {
      deleteMenuItem[0].insertAdjacentHTML('beforebegin', this.createWidgetMenuItem(action, title, isViewModeMenu));
    }
    this.updateToolbarVisibility(widgetId);
    this.widgetClickListeners();
  }

  createWidgetContent(div, additionalMenus) {
    const widgetId = this.widget.DashboardsWidgetId;
    const vue = this.interface.getVueParent();
    // const editModeFlag = this.interface.editModeFlag();
    const toolbarVisible = this.isToolbarVisible() ? 'display: inline-block; ' : 'display: none; ';
    const refreshBtn = `<button type="button" id="btn-refresh-${widgetId}" title="Refresh" class="btn refresh-widget-btn"><i class="mdi mdi-refresh"></i></button>`;
    const expandViewBtn = `<button type="button" id="btn-expand-${widgetId}" title="Expand" class="btn expand-widget-btn"><i class="mdi mdi-arrow-expand"></i></button>`;
    const toolbar = `<div id="widget-toolbar-${widgetId}" class="widget-toolbar" style="${toolbarVisible}z-index: 20; line-height: 1.0;">`
      + `<div id="widget-button-menu-${widgetId}" class="widget-button-menu" data-widgetid="${widgetId}" ><span class="mdi mdi-menu"></div>`
      + '</div>'
      + `<div id="widget-menu-${widgetId}" role="menu" class="v-menu__content theme--dark menuable__content__active" style="display: none; min-width: 0px; top: 24px; left: 3px; transform-origin: left top; z-index: 3999;">`
      + '<div class="v-list dense v-sheet theme--dark">'
      + `${additionalMenus}`
      + `${this.createWidgetMenuItem('delete', vue.$t.apply(vue, ['Delete Widget']))}`
      + '</div>'
      + '</div>';

    this.grid.update(
      this.getGridStackElement(), {
        content: `${refreshBtn}${expandViewBtn}${toolbar}${div}`,
      },
    );

    this.updateToolbarVisibility(widgetId);
  }

  updateToolbarVisibility(widgetId) {
    // Toggle toolbar visibility
    const toolbarEl = document.getElementById(`widget-toolbar-${widgetId}`);
    if (toolbarEl) {
      toolbarEl.style.display = this.isToolbarVisible() ? 'inline-block' : 'none';
    }
    const widgetsMenuItem = document.querySelectorAll(`.widget-menu-item-editmode[data-widgetid="${widgetId}"]`);
    widgetsMenuItem.forEach((x) => {
      // eslint-disable-next-line no-param-reassign
      x.style.display = this.interface.editModeFlag() ? 'inline-block' : 'none';
    });
  }

  installGridStackElementListener() {
    const listen = {
      widget: this.getGridStackElement(),
      event: 'click',
      func: () => {
        this.updateActiveWidget();
        this.interface.clearResizeEventFlag();
      },
    };
    listen.widget.addEventListener(listen.event, listen.func);
    this.registeredOnClickListeners.push(listen);
  }

  installWidgetButtonMenuElementListener() {
    const widgetsMenu = document.getElementById(`widget-button-menu-${this.widget.DashboardsWidgetId}`);
    const listen = {
      widget: widgetsMenu,
      event: 'click',
      func: (e) => this.onWidgetMenuClick(e),
    };
    listen.widget.addEventListener(listen.event, listen.func);
    this.registeredOnClickListeners.push(listen);
  }

  installWidgetRefreshButtonClickListener() {
    const btnRefresh = document.getElementById(`btn-refresh-${this.widget.DashboardsWidgetId}`);
    const listen = {
      widget: btnRefresh,
      event: 'click',
      func: (e) => this.onWidgetBtnRefreshClick(e),
    };
    listen.widget.addEventListener(listen.event, listen.func);
    this.registeredOnClickListeners.push(listen);
  }

  installWidgetExpandButtonClickListener() {
    const btnExpand = document.getElementById(`btn-expand-${this.widget.DashboardsWidgetId}`);
    const listen = {
      widget: btnExpand,
      event: 'click',
      func: (e) => this.onWidgetBtnExpandClick(e),
    };
    listen.widget.addEventListener(listen.event, listen.func);
    this.registeredOnClickListeners.push(listen);
  }

  installWidgetMenuItemElementListener() {
    const widgetsMenuItem = document.querySelectorAll(`.widget-menu-item[data-widgetid="${this.widget.DashboardsWidgetId}"]`);
    for (let i = 0; i < widgetsMenuItem.length; i += 1) {
      const listen = {
        widget: widgetsMenuItem[i],
        event: 'click',
        func: (e) => this.onWidgetMenuItemClick(e),
      };
      listen.widget.addEventListener(listen.event, listen.func);
      this.registeredOnClickListeners.push(listen);
    }
  }

  installWidgetMenuClickAwayListener() {
    const listen = {
      widget: document,
      event: 'click',
      func: (e) => this.onWidgetMenuItemClickAway(e),
    };
    listen.widget.addEventListener(listen.event, listen.func);
    this.registeredOnClickListeners.push(listen);
  }

  removeAllListeners() {
    this.registeredOnClickListeners.forEach((listen) => {
      listen.widget.removeEventListener(listen.event, listen.func);
    });
  }

  widgetClickListeners() {
    this.removeAllListeners();
    this.installGridStackElementListener();
    this.installWidgetButtonMenuElementListener();
    this.installWidgetMenuItemElementListener();
    this.installWidgetMenuClickAwayListener();
    this.installWidgetRefreshButtonClickListener();
    this.installWidgetExpandButtonClickListener();
  }

  // Toggle the widget edit menu
  onWidgetMenuClick(e) {
    const widgetId = this.widget.DashboardsWidgetId;
    const menu = document.getElementById(`widget-menu-${widgetId}`);
    if (menu.style.display === 'none') {
      menu.style.display = 'inline-block';
    } else {
      menu.style.display = 'none';
    }
    e.stopPropagation();
  }

  // Execute the widget edit menu items
  onWidgetMenuItemClick(e) {
    const menuButton = e.currentTarget;
    const { action } = menuButton.dataset;
    if (action === 'delete') {
      this.interface.removeWidget(this.widget.DashboardsWidgetId);
    } else if (action === 'refresh') {
      this.interface.refreshWidget(this.widget.DashboardsWidgetId);
    } else if (action === 'expand') {
      this.interface.expandWidget(this.widget.DashboardsWidgetId);
    }
    const menu = document.getElementById(`widget-menu-${this.widget.DashboardsWidgetId}`);
    if (menu && menu.style) menu.style.display = 'none';
    e.stopPropagation();
  }

  onWidgetMenuItemClickAway(e) {
    const widgetId = this.widget.DashboardsWidgetId;
    const menu = document.getElementById(`widget-menu-${widgetId}`);
    if (menu && !menu.contains(e.target)) {
      menu.style.display = 'none';
    }
  }

  onWidgetBtnRefreshClick() {
    this.interface.refreshWidget(this.widget.DashboardsWidgetId);
    this.originalState = null;
  }

  async enterFullscreen() {
    const widgetElement = this.getGridStackElement();
    if (widgetElement) {
      // Save current state
      this.originalState = {
        x: this.widget.AxisX,
        y: this.widget.AxisY,
        width: this.widget.AxisWidth,
        height: this.widget.AxisHeight,
      };

      const gridHeightInCells = Math.ceil(window.innerHeight / this.interface.cellHeight() - 9);

      // Set widget to fullscreen
      this.grid.update(widgetElement, {
        x: 0,
        y: 0,
        w: 12, // Assuming 12 columns, adjust accordingly
        h: gridHeightInCells,
      });

      this.resizeToContainer(this.grid);
      this.updateToolbarVisibility(this.widget.DashboardsWidgetId);
    }
  }

  async exitFullscreen() {
    this.interface.refreshWidget(this.widget.DashboardsWidgetId);
    this.originalState = null;
  }

  onWidgetBtnExpandClick() {
    if (this.originalState) {
      this.exitFullscreen();
      this.interface.expandWidget();
    } else {
      this.enterFullscreen();
    }
  }
}
