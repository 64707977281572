import api from '@/api/importal';
import i18n from '@/plugins/i18n';
import jsonHandling from '@/components/helpers/jsonHandling';

function NotNull(x) {
  return x !== null && x !== undefined;
}

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

function isActiveSensor(item) {
  // eslint-disable-next-line no-bitwise
  if (item.StateFlag & 0x001) {
    return true;
  }
  return false;
}

function getPriority(item) {
  const priority = item.NotificationPriority;
  if (item.IsScheduled) {
    return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Diagnostic');
  }
  switch (priority) {
    case -25:
      return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Diagnostic');
    case null:
    case 0:
    case undefined:
      return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Low');
    case 25:
      return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Medium');
    case 50:
      return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.High');
    case 75:
      return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Critical');
    default:
      return i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Medium');
  }
}

function isAckSensor(item) {
  // eslint-disable-next-line no-bitwise
  if (item.StateFlag & 0x002) {
    return true;
  }
  return false;
}

function getJournalDescription(item) {
  let description = '';
  if (item.FunctionId === 'AlertEvaluator') {
    if (item.IsNominalSensor) {
      // eslint-disable-next-line no-bitwise
      if (item.StateFlags & 0x0001) {
        // active nominal alert
        if (NotNull(item.ViolationValue)) {
          // If there is a violation value, then the value started a new violation
          description = i18n.t('Alert.Manage.AlertList.ThresholdExceeded');
        }
      } else {
        // inactive nominal alert
        description = i18n.t('Alert.Manage.AlertList.ThresholdNoLongerExceeded');
      }
    } else if (item.IsScheduledSensor) {
      description = `${i18n.t('Alert.Manage.AlertList.ScheduledAlert')} `;
    } else {
      // Missing Data
      // eslint-disable-next-line no-bitwise
      description = item.StateFlags & 0x0001
        ? i18n.t('Alert.Manage.AlertList.MissingDataActive')
        : i18n.t('Alert.Manage.AlertList.MissingDataInactive');
    }
  } else if (item.FunctionId === 'AlertBasicAck' || item.FunctionId === 'AlertSensorAck') {
    // Someone acknowledged
    const metadatajson = JSON.parse(item.MetadataJson);
    const message = jsonHandling.isJsonString(metadatajson.Message)
      ? JSON.parse(metadatajson.Message).Message
      : metadatajson.Message;

    description = `${i18n.t('Alert.Manage.AlertList.AlertWasAck')} ${i18n.t('Alert.Manage.AlertList.By')} ${metadatajson.UserName || ''} `;
    if (message) {
      description += `${i18n.t('Alert.Manage.AlertList.Reason')} ${message}`;
    }
  } else if (item.FunctionId === 'AlertUpdateConfig') {
    // Someone acknowledged
    const metadatajson = JSON.parse(item.MetadataJson);
    description = `${i18n.t('Alert.Manage.AlertList.AlertConfigModified')} ${i18n.t('Alert.Manage.AlertList.By')} ${metadatajson.UserName || ''} `;
  } else if (item.FunctionId === 'AlertBasicMute' || item.FunctionId === 'AlertSensorMute') {
    // Someone muted or unmuted
    const metadatajson = JSON.parse(item.MetadataJson);
    // eslint-disable-next-line no-bitwise
    description = `${i18n.t(`Alert.Manage.AlertList.${item.StateFlags & 0x0004 ? 'AlertWasMuted' : 'AlertWasUnmuted'}`)} `
      + `${i18n.t('Alert.Manage.AlertList.By')} ${metadatajson.UserName || ''}`;
  } else if (item.FunctionId === 'AlertBasicUpdate' || item.FunctionId === 'AlertUpdateForSensor') {
    // Someone update the state
    const metadatajson = JSON.parse(item.MetadataJson);
    description = `${i18n.t('Alert.Manage.AlertList.AlertWasModified')} ${i18n.t('Alert.Manage.AlertList.By')} ${metadatajson.UserName || ''}`;
  } else if (item.FunctionId === 'AlertEmailSettingsUpdate') {
    const metadatajson = JSON.parse(item.MetadataJson);
    description = `${i18n.t('Alert.Manage.AlertList.AlertEmailModified')} ${i18n.t('Alert.Manage.AlertList.By')} ${metadatajson.UserName || ''} `;
  }

  return description;
}

const state = {
  rowdata: [],
  journalRowdata: [],
};

const getters = {
  FunctionIdTypes: () => [
    {
      type: 'Ack',
      name: i18n.t('Alert.AlertStatus.FunctionIdTypes.Ack'),
      functionIds: ['AlertBasicAck', 'AlertSensorAck'],
    },
    {
      type: 'Mute',
      name: i18n.t('Alert.AlertStatus.FunctionIdTypes.Mute'),
      functionIds: ['AlertBasicMute', 'AlertSensorMute'],
    },
    {
      type: 'Alert',
      name: i18n.t('Alert.AlertStatus.FunctionIdTypes.Alert'),
      functionIds: ['AlertEvaluator'],
    },
    {
      type: 'Update',
      name: i18n.t('Alert.AlertStatus.FunctionIdTypes.Update'),
      functionIds: ['AlertBasicUpdate', 'AlertUpdateForSensor'],
    },
    {
      type: 'UpdateDashboard',
      name: i18n.t('Alert.AlertStatus.FunctionIdTypes.UpdateDashboard'),
      functionIds: ['AlertEmailSettingsUpdate'],
    },
    {
      type: 'UpdateConfig',
      name: i18n.t('Alert.AlertStatus.FunctionIdTypes.UpdateConfig'),
      functionIds: ['AlertUpdateConfig'],
    },
  ],
};

const actions = {
  async list({ commit }, payload) { // SendDashboardId here is the one in alert
    let params = `?searchInstrument=${payload?.searchInstrument || ''}`
      + `&searchSensor=${payload?.searchSensor || ''}`;

    if (payload?.Id) {
      params += `&Id=${payload.Id}`;
    }

    return withWait({ commit }, () => api.get(`AlertListWithoutSensors${params}`))
      .then((resp) => commit('setRowData', resp.data));
  },
  async listSensorsAlert({ commit }, payload) { // SendDashboardId here is the one in sensor
    return withWait({ commit }, () => api.get(`AlertSensorList?Id=${payload.Id}`))
      .then((resp) => commit('setRowDataSensor', resp.data));
  },
  async journal({ commit }, payload) {
    return withWait({ commit }, () => api.get('AlertBasicJournal', { params: payload }))
      .then((resp) => {
        commit('setJournalRowData', resp.data);
        return resp.data;
      });
  },
  async mute({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertSensorMute', payload))
      .then((resp) => commit('updateRowDataSensorFromApi', resp.data[0]));
  },
  async ack({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertSensorAck', payload))
      .then((resp) => commit('updateRowDataSensorFromApi', resp.data[0]));
  },
  async merge({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertSensorMigrate', payload))
      .then((resp) => commit('skip', resp.data[0]));
  },
  async refreshEdit({ commit }, payload) {
    commit('updateRowData', payload);
  },
  async refreshEditSensor({ commit }, payload) {
    commit('updateRowDataSensor', payload);
  },
  async deleteConfig({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertConfigRemove', payload))
      .then(commit('removeRowConfigData', payload));
  },
  async delete({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertSensorRemove', payload))
      .then(commit('removeRowData', payload));
  },
  async shareWithGroup({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicShareWithGroup', payload))
      .then((resp) => commit('updateRowData', resp.data));
  },
  async shareWithUser({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicShareWithUser', payload))
      .then((resp) => commit('updateRowData', resp.data));
  },
  async shareWithGroupV3({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicShareWithGroupV3', payload))
      .then((resp) => commit('updateRowData', resp.data));
  },
  async shareWithUserV3({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicShareWithUserV3', payload))
      .then((resp) => commit('updateRowData', resp.data));
  },
};

const mutations = {
  setRowData(state, payload) {
    const payloadWithSPA = payload.map((alert) => ({
      ...alert,
      State: alert.isActive ? i18n.t('Alert.Manage.AlertList.Active') : i18n.t('Alert.Manage.AlertList.Inactive'),
      Priority: getPriority(alert),
      Ack: alert.isAck ? i18n.t('Alert.Manage.AlertList.Acked') : i18n.t('Alert.Manage.AlertList.Unacked'),
      QuantityActive: alert.quantityActive,
      QuantityAlerts: alert.quantityAlerts,
      LastViolation: alert.lastViolation,
    }));
    state.rowdata = payloadWithSPA;
  },
  setRowDataSensor(state, payload) {
    const payloadWithSPA = payload.map((alert) => ({
      ...alert,
      State: isActiveSensor(alert) ? i18n.t('Alert.Manage.AlertList.Active') : i18n.t('Alert.Manage.AlertList.Inactive'),
      Priority: getPriority(alert),
      Ack: isAckSensor(alert) ? i18n.t('Alert.Manage.AlertList.Acked') : i18n.t('Alert.Manage.AlertList.Unacked'),
    }));

    state.rowdata = payloadWithSPA;
  },
  setJournalRowData(state, payload) {
    state.journalRowdata = payload.map((item) => {
      const typeItem = getters.FunctionIdTypes()
        .find((type) => type.functionIds.includes(item.FunctionId));

      return {
        ...item,
        Description: getJournalDescription(item),
        Type: NotNull(typeItem) ? typeItem.type : '',
      };
    });
  },
  updateRowData(state, payload) {
    const updatedAlert = {
      ...payload[0],
      State: payload[0].isActive ? i18n.t('Alert.Manage.AlertList.Active') : i18n.t('Alert.Manage.AlertList.Inactive'),
      Priority: getPriority(payload[0]),
      Ack: payload[0].isAck ? i18n.t('Alert.Manage.AlertList.Acked') : i18n.t('Alert.Manage.AlertList.Unacked'),
      QuantityActive: payload[0].quantityActive,
      QuantityAlerts: payload[0].quantityAlerts,
      LastViolation: payload[0].lastViolation,
    };

    const doesMatch = (alert) => alert.BasicAlertConfigId === updatedAlert.BasicAlertConfigId;

    const index = state.rowdata.findIndex(doesMatch);

    if (index !== -1) {
      state.rowdata.splice(index, 1, updatedAlert);
    }
  },

  updateRowDataSensor(state, payload) {
    const updatedAlert = {
      ...payload,
      State: isActiveSensor(payload) ? i18n.t('Alert.Manage.AlertList.Active') : i18n.t('Alert.Manage.AlertList.Inactive'),
      Priority: getPriority(payload),
      Ack: isAckSensor(payload) ? i18n.t('Alert.Manage.AlertList.Acked') : i18n.t('Alert.Manage.AlertList.Unacked'),
    };

    const doesMatch = (alert) => alert.SensorId === updatedAlert.SensorId;

    const index = state.rowdata.findIndex(doesMatch);

    if (index !== -1) {
      state.rowdata.splice(index, 1, updatedAlert);
    }
  },

  updateRowDataSensorFromApi(state, payload) {
    const doesMatch = (alert) => alert.SensorId
      === payload.BasicAlertConfigSensors[0].SensorId;

    const index = state.rowdata.findIndex(doesMatch);

    if (index !== -1) {
      const updatedAlert = {
        ...state.rowdata[index],
        StateFlag: payload.BasicAlertConfigSensors[0].StateFlag,
        Priority: getPriority(payload),
        Ack: isAckSensor(payload.BasicAlertConfigSensors[0]) ? i18n.t('Alert.Manage.AlertList.Acked') : i18n.t('Alert.Manage.AlertList.Unacked'),
      };
      state.rowdata.splice(index, 1, updatedAlert);
    }
  },
  removeRowData(state, payload) {
    payload.SensorIds.forEach((id) => {
      const doesMatch = (alert) => alert.SensorId === id;

      const index = state.rowdata.findIndex(doesMatch);

      if (index !== -1) {
        state.rowdata.splice(index, 1);
      }
    });
  },
  removeRowConfigData(state, payload) {
    const doesMatch = (alert) => alert.BasicAlertConfigId === payload.BasicAlertConfigId;

    const index = state.rowdata.findIndex(doesMatch);

    if (index !== -1) {
      state.rowdata.splice(index, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
