/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsInstrument extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          width: 75,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            confirm: (event) => (`Instrument "${event.data.Name}"`),
            clicked: (event) => {
              this.EditInstrument(event.data);
            },
          },
        },
        {
          field: 'InstrumentId',
          headerName: i18n.t('Devices.Header_Instruments.Id'),
          width: 80,
        },
        {
          field: 'Name',
          headerName: i18n.t('Devices.Header_Instruments.Name'),
          width: 200,
          editable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'AccessGroupName',
          headerName: i18n.t('Devices.Header_Instruments.AccessGroupName'),
          width: 300,
          filter: true,
          sortable: true,
        },
        {
          field: 'GatewayName',
          headerName: i18n.t('Devices.Header_Instruments.GatewayName'),
          width: 350,
          filter: true,
          sortable: true,
        },
        {
          field: 'Geolocation',
          headerName: i18n.t('Devices.Header_Instruments.Location'),
          width: 180,
          cellRenderer: 'PointGeolocationCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'Layer',
          headerName: i18n.t('Devices.Header_Instruments.Layer'),
          width: 160,
          filter: true,
          sortable: true,
          editable: true,
        },
        {
          field: 'Icon',
          headerName: i18n.t('Devices.Header_Instruments.Icon'),
          width: 160,
          filter: true,
          sortable: true,
          editable: true,
        },
        {
          width: 50,
          resizable: false,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`Instrument "${event.data.Name}"`),
            clicked: (event) => {
              this.apiDevInstrumentRemove(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    return {
      getRows: (params) => {
        importal.get('DevInstrumentList')
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {
      Name(event, successCallback, failCallback) {
        importal
          .post('DevInstrumentRename', {
            InstrumentId: event.data.InstrumentId,
            Name: event.data.Name,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      Layer(event, successCallback, failCallback) {
        importal
          .post('DevInstrumentAttributeUpdateStr', {
            InstrumentId: event.data.InstrumentId,
            Name: '$LAYER$',
            ValueStr: event.data.Layer,
          })
          // eslint-disable-next-line no-unused-vars
          .then((resp) => {
            // successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      Icon(event, successCallback, failCallback) {
        importal
          .post('DevInstrumentAttributeUpdateStr', {
            InstrumentId: event.data.InstrumentId,
            Name: '$ICON$',
            ValueStr: event.data.Icon,
          })
          // eslint-disable-next-line no-unused-vars
          .then((resp) => {
            // successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    return new Promise((resolve) => {
      importal.get('DevGatewayList')
        .then((resp) => {
          resolve({
            Model: {
              GateWay: null,
              Name: null,
            },
            Schema: {
              GateWay: {
                label: i18n.t('Devices.Instruments.AddNew.Gateway'),
                type: 'select',
                returnObject: true,
                itemText: 'Name',
                items: resp.data,
                flex: 12,
              },
              Name: { label: i18n.t('Devices.Instruments.AddNew.InstrumentName'), type: 'text', flex: 12 },
            },
          });
        });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('DevInstrumentNew', {
        DataGatewayId: data.GateWay.DataGatewayId,
        Name: data.Name,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevInstrumentRemove(api, data) {
    const { vue } = this;
    vue.$store.commit('app/pleaseWait', true);
    await importal
      .post('DevInstrumentRemove', {
        InstrumentId: data.InstrumentId,
      })
      .catch((err) => {
        if (err.response.data.exception) {
          // this.showError(err.response.data.exception);
        }
        vue.$store.commit('app/pleaseWait', false);
      })
      .then((resp) => {
        api.setRowData(resp.data);
        vue.$store.commit('app/pleaseWait', false);
      });
  }

  async EditInstrument(data) {
    this.vue.$router.push({ name: 'InstrumentList', params: { id: data.InstrumentId } });
  }
}
