/* eslint-disable no-bitwise */
<template>
  <v-card
    class="right-sidebar"
    tile
    outlined
    elevation="10"
  >
    <ErrorDialog
      v-model="Error.Show"
      :message="Error.Message"
      :no-cancel="true"
      :title="Error.Title"
    />
    <v-card-title>
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.SubscribeSetting') }}
    </v-card-title>

    <!-- COMMON: SELECT SENSOR  -->
    <SelectSensorStep
      v-if="showSelectSensorStep"
      :sensor-list="SensorList"
      :selected-sensor="Form.SelectedSensor"
      @update:selectedSensor="Form.SelectedSensor = $event"
    />

    <!-- COMMON: SELECT ALERT TYPE -->
    <SelectAlertType
      v-if="showSelectAlertTypeStep"
      :alert-type-list="AlertTypeList"
      :selected-alert-type="Form.SelectedAlertType"
      @update:selectedAlertType="Form.SelectedAlertType = $event"
    />
    <!-- NOMINALITY: Trigger selection -->
    <nominality-selector
      v-if="showNominalityTriggerStep"
      :nominality-trigger-type="NominalityTriggerType"
      :second-nominality-trigger-type="SecondNominalityTriggerType"
      :alert-line-type-list="AlertLineTypeList"
      :nominality-trigger="Form.NominalityTrigger"
      :nominality-trigger-value="Form.NominalityTriggerValue"
      :another-comparator-checkbox="AnotherComparatorCheckbox"
      :more-nominality-trigger="Form.MoreNominalityTrigger"
      :more-nominality-trigger-value="Form.MoreNominalityTriggerValue"
      :selected-line="selectedLine"
      :pick-color-line="pickColorLine"
      :pick-color="pickColor"
      @update:nominalityTrigger="Form.NominalityTrigger = $event"
      @update:nominalityTriggerValue="Form.NominalityTriggerValue = $event"
      @update:AnotherComparatorCheckbox="AnotherComparatorCheckbox = $event"
      @update:moreNominalityTrigger="Form.MoreNominalityTrigger = $event"
      @update:moreNominalityTriggerValue="Form.MoreNominalityTriggerValue = $event"
      @update:selectedLine="selectedLine = $event"
      @update:pickColorLine="pickColorLine = $event"
      @update:pickColor="pickColor = $event"
    />
    <!-- MISSING DATA/SCHEDULED: Time delay selection -->
    <date-alert-selector
      v-if="showMissingDataTimeStep || showScheduledTimeStep"
      :delay-scale="Form.DelayScale"
      :delay-value="Form.DelayValue"
      :value-delay-in-hours-scale="ValueDelayInHoursScale"
      :show-missing-data-time-step="showMissingDataTimeStep"
      :show-scheduled-time-step="showScheduledTimeStep"
      @update:delayScale="Form.DelayScale = $event"
      @update:delayValue="Form.DelayValue = $event"
      @update:valueDelayInHoursScale="ValueDelayInHoursScale = $event"
    />

    <!-- COMMON: Alert timing -->
    <alert-timing
      v-if="showAlertTimingStep"
      :active-from="Form.ActiveFrom"
      :active-to="Form.ActiveTo"
      :selected-alert-type="Form.SelectedAlertType"
      :valid-rate-in-min-value="Form.ValidRateInMinValue"
      :valid-rate-in-min-scale="Form.ValidRateInMinScale"
      :validation-rate-in-min-scale="ValidationRateInMinScale"
      :valid-day="Form.ValidDay"
      :time-of-day-start="Form.timeOfDayStart"
      :time-of-day-end="Form.timeOfDayEnd"
      @update:ActiveFrom="Form.ActiveFrom = $event"
      @update:ActiveTo="Form.ActiveTo = $event"
      @update:ValidRateInMinValue="Form.ValidRateInMinValue = $event"
      @update:ValidRateInMinScale="Form.ValidRateInMinScale = $event"
      @update:ValidDay="Form.ValidDay = $event"
      @update:TimeOfDayStart="Form.timeOfDayStart = $event"
      @update:TimeOfDayEnd="Form.timeOfDayEnd = $event"
    />

    <!-- COMMON: Alert mode -->
    <alert-mode
      v-if="showAlertModeStep"
      :alert-mode-list="AlertModeList"
      :alert-priority-list="AlertPriorityList"
      :alert-mode="Form.AlertMode"
      :alert-priority="Form.AlertPriority"
      @update:AlertMode="Form.AlertMode = $event"
      @update:AlertPriority="Form.AlertPriority = $event"
    />

    <!-- COMMON: Alert text -->
    <alert-text
      v-if="showAlertTextStep"
      :alert-subject="Form.AlertSubject"
      :alert-name="Form.AlertName"
      :alert-long-name="Form.LongName"
      :alert-body="Form.AlertBody"
      :dashboard-list="Form.DashboardList"
      :mail-list="Form.MailList"
      :mail-lists="mailLists"
      :edit-sensor="editSensor"
      :dashboards-w-none="dashboardsWNone"
      :edit-config="editConfig"
      :sensor-alert-name="Form.SensorAlertName"
      @update:MailList="Form.MailList = $event"
      @update:DashboardList="Form.DashboardList = $event"
      @update:AlertBody="Form.AlertBody = $event"
      @update:AlertSubject="Form.AlertSubject = $event"
      @update:AlertName="Form.AlertName = $event"
      @update:LongName="Form.LongName = $event"
      @update:SensorAlertName="Form.SensorAlertName = $event"
    />

    <!-- NEW ALERTS -->
    <v-container
      v-if="showPeriodAggregationForNewAlerts"
    >
      <v-select
        v-model="Form.AggregationValue"
        :items="AggregateBy2Query"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.SensorAggregation')"
        outlined
      />
    </v-container>

    <period-aggregation-card
      v-if="showPeriodAggregationForNewAlerts"
      v-models:aggregateMultiplier="Form.AggregateMultiplier"
      v-models:aggregateForEvery="Form.AggregateForEvery"
      v-models:aggregateUseTimeZone="Form.AggregateUseTimeZone"
      :show="true"
    />

    <v-divider />
    <v-card-actions>
      <v-btn
        text
        @click="$emit('close-subscribe')"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Cancel') }}
      </v-btn>
      <v-btn
        v-if="showBackButton"
        :disabled="disableBackButton"
        text
        @click="form_back()"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Back') }}
      </v-btn>
      <v-btn
        v-if="showNextButton"
        :disabled="disableNextButton"
        text
        @click="form_next()"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Next') }}
      </v-btn>
      <v-btn
        v-if="showSaveButton"
        :disabled="disableSaveButton"
        text
        @click="SaveChoices()"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ErrorDialog from '@/components/ConfirmDialog.vue';
import SelectSensorStep from './SelectSensorStep.vue';
import SelectAlertType from './SelectAlertType.vue';
import DateAlertSelector from './DateAlertSelector.vue';
import NominalitySelector from './NominalitySelector.vue';
import datehandling from '@/components/datehandling';
import AlertTiming from './AlertTiming.vue';
import AlertMode from './AlertMode.vue';
import AlertText from './AlertText.vue';
import PeriodAggregationCard from '@/dashboard/components/graphform/PeriodAggregationCard.vue';

function SecondsToTimeText(secs) {
  const s = Number(secs);
  const hh = `${Math.floor(s / 3600)}`.padStart(2, '0');
  const mm = `${((s % 3600) / 60).toFixed(0)}`.padStart(2, '0');
  return `${hh}:${mm}`;
}

function TimeTextToSeconds(tt) {
  const hh = Number(String(tt).substring(0, 2));
  const mm = Number(String(tt).substring(3, 5));
  return (hh * 3600) + (mm * 60);
}

function NotNull(x) {
  return x !== null && x !== undefined;
}

function GetMailListUid(mailList) {
  if (NotNull(mailList)
      && NotNull(mailList.MailListUid)) {
    return mailList.MailListUid;
  }
  return '00000000-0000-0000-0000-000000000000';
}

export default {
  components: {
    ErrorDialog,
    SelectSensorStep,
    SelectAlertType,
    NominalitySelector,
    DateAlertSelector,
    AlertTiming,
    AlertMode,
    AlertText,
    PeriodAggregationCard,
  },
  props: {
    sensorList: { type: Object, default: () => null },
    editSensor: { type: Boolean, default: () => false },
    editConfig: { type: Boolean, default: () => false },
    selectedRows: { type: Array, default: () => null },
    newAlert: { type: Boolean, default: () => false },
  },
  data() {
    return {
      AlertTypeList: [
        { Name: 'Nominality', icon: 'mdi-numeric' },
        { Name: 'Missing Data', icon: 'mdi-help-circle-outline' },
        { Name: 'Scheduled', icon: 'mdi-calendar-month' },
      ],
      AlertLineTypeList: [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.dot'), Value: 'dot' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.dash'), Value: 'dash' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.dashdot'), Value: 'dashdot' },
      ],
      AlertModeList: [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Transition'), Value: 1 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Repetitive'), Value: 0 },
      ],
      AlertPriorityList: [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Diagnostic'), Value: -25 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Low'), Value: 0 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Medium'), Value: 25 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.High'), Value: 50 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Critical'), Value: 75 },
      ],
      AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],

      SensorList: [], // List of sensors
      Settings: null, // Parsed WidgetSettings

      EditedAlertData: null,

      type: 'selectedColor',
      typeLine: 'selectedColorLine',
      selectedColor: '#FF0000FF',
      selectedColorLine: '#FF0000',
      selectedLine: 'dashdot',
      menuStepCounter: 0,
      Form: {
        SelectedSensor: null,
        SelectedAlertType: null,
        NominalityTrigger: null,
        NominalityTriggerValue: null,
        MoreNominalityTrigger: null,
        MoreNominalityTriggerValue: null,
        ActiveFrom: new Date(),
        ActiveTo: new Date(2099, 0, 1),
        ValidRateInMinValue: '1',
        ValidRateInMinScale: null,
        ValidDay: ['0', '2', '4', '8', '16', '32', '0'],

        timeOfDayStart: '00:00',
        timeOfDayEnd: '24:00',

        DelayValue: '1',
        DelayScale: null,

        AlertName: null,
        LongName: null,
        SensorAlertName: null,
        AlertSubject: null,
        AlertBody: null,
        AlertMode: 0,
        AlertPriority: 25,

        DashboardList: [],

        sendFullDashboardFlag: false,
        sendFullDashboardData: {
          DashboardId: null,
          Kind: 'pdf',
          TemplateName: 'Dashboard',
        },

        MailList: {
          Name: 'None',
          MailListUid: undefined,
        },
        AggregateMultiplier: 1,
        AggregateForEvery: 'Minutes',
        AggregateUseTimeZone: true,
        AggregationValue: 'AVG',
      },

      Error: {
        Message: '',
        Title: 'Error',
        Show: false,
        acceptCallback: null,
      },

      AnotherComparatorCheckbox: false, // CHECKBOX TO ADD ANOTHER COMPARATOR
    };
  },
  computed: {
    ...mapState('dashboard', ['selectedDashboard', 'dashboards']),
    ...mapState('subscribe', ['mailLists', 'dashboardsWNone']),
    ...mapGetters('app', ['timezone']),

    // FORM SHOW SEQUENCING
    showSelectSensorStep() {
      return this.menuStepCounter === 0;
    },
    showSelectAlertTypeStep() {
      return this.menuStepCounter === 1;
    },
    showNominalityTriggerStep() {
      return this.menuStepCounter === 2
        && this.Form.SelectedAlertType
        && this.Form.SelectedAlertType.Name === 'Nominality';
    },
    showMissingDataTimeStep() {
      return this.menuStepCounter === 2
        && this.Form.SelectedAlertType
        && this.Form.SelectedAlertType.Name === 'Missing Data';
    },
    showScheduledTimeStep() {
      return this.menuStepCounter === 2
        && this.Form.SelectedAlertType
        && this.Form.SelectedAlertType.Name === 'Scheduled';
    },
    showMoreNominalityTriggerStep() {
      return this.showNominalityTriggerStep
        && this.Form.NominalityTrigger
        && NotNull(this.Form.NominalityTriggerValue);
    },
    showAlertTimingStep() {
      return this.menuStepCounter === 3;
    },
    showAlertModeStep() {
      return this.menuStepCounter === 4;
    },
    showAlertTextStep() {
      return this.menuStepCounter === 5;
    },
    showPeriodAggregationForNewAlerts() {
      return this.menuStepCounter === 6;
    },

    // BUTTON SHOW SEQUENCING
    showBackButton() {
      if (this.menuStepCounter === 2 && (this.editConfig)) {
        return false;
      }
      return true;
    },
    showNextButton() {
      return !this.showSaveButton;
    },
    showSaveButton() {
      if (this.newAlert) {
        return this.showPeriodAggregationForNewAlerts;
      }
      return this.showAlertTextStep;
    },
    disableBackButton() {
      if (this.editSensor) {
        return true;
      }
      if (this.newAlert && this.menuStepCounter === 1) {
        return true;
      }
      return this.menuStepCounter === 0;
    },
    disableNextButton() {
      if (this.showSelectSensorStep && this.Form.SelectedSensor) {
        return false;
      }
      if (this.showSelectAlertTypeStep && this.Form.SelectedAlertType) {
        return false;
      }
      if (this.showAlertTextStep && this.newAlert
          && this.Form.AlertName
          && this.Form.AlertSubject) {
        return false;
      }
      if (this.showAlertModeStep) {
        return false;
      }
      if (
        (
          !this.AnotherComparatorCheckbox && this.showNominalityTriggerStep
          && (
            NotNull(this.Form.NominalityTrigger)
            && (NotNull(this.Form.NominalityTriggerValue) && this.Form.NominalityTriggerValue !== '')
          )
        )
      ) {
        return false;
      }
      if (
        this.AnotherComparatorCheckbox && this.showNominalityTriggerStep
        && (
          NotNull(this.Form.NominalityTrigger)
          && (NotNull(this.Form.NominalityTriggerValue) && this.Form.NominalityTriggerValue !== '')
          && NotNull(this.Form.MoreNominalityTrigger)
          && (NotNull(this.Form.MoreNominalityTriggerValue) && this.Form.MoreNominalityTriggerValue !== '')
        )
      ) {
        return false;
      }
      if (this.showAlertTimingStep
            && this.Form.ActiveFrom
            && this.Form.ActiveTo
            && this.Form.ValidRateInMinValue
            && this.Form.ValidRateInMinScale) {
        return false;
      }
      if ((this.showMissingDataTimeStep || this.showScheduledTimeStep)
            && this.Form.DelayValue
            && this.Form.DelayScale) {
        return false;
      }
      return true;
    },
    disableSaveButton() {
      if (this.showAlertTextStep
          && this.Form.AlertName
          && this.Form.AlertSubject) {
        return false;
      }

      if (this.showPeriodAggregationForNewAlerts && this.Form.AggregateMultiplier) {
        return false;
      }

      return true;
    },

    // VARIA
    isSecondNominalityTriggerValid() {
      const y = this.Form.MoreNominalityTrigger;
      return y
          && this.SecondNominalityTriggerType
            .filter((x) => x.Type === y.Type).length > 0;
    },
    SecondNominalityTriggerType() {
      if (this.Form.NominalityTrigger) {
        const allowedTypes = this.NominalityTriggerType
          .filter((x) => x.Category !== this.Form.NominalityTrigger.Category);
        return allowedTypes;
      }
      return this.NominalityTriggerType;
    },

    // NOMINALITY TYPE AS A FUNCTION, TO LET IT CHANGE BETWEEN LANGUAGES
    NominalityTriggerType() {
      return [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.GreaterThan'), Type: '>', Category: 'G' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.GreaterOrEqualThan'), Type: '>=', Category: 'G' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.LesserThan'), Type: '<', Category: 'L' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.LesserOrEqualThan'), Type: '<=', Category: 'L' },
      ];
    },

    // VALIDATIONRATESCALE AS A FUNCTION, TO LET IT CHANGE BETWEEN LANGUAGES
    ValidationRateInMinScale() {
      return [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Months'), Scale: 40320 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Weeks'), Scale: 10080 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Days'), Scale: 1440 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Hours'), Scale: 60 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Minutes'), Scale: 1 },
      ];
    },

    ValueDelayInHoursScale() {
      return [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Months'), Scale: 672 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Weeks'), Scale: 168 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Days'), Scale: 24 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Hours'), Scale: 1 },
      ];
    },

    pickColor: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    pickColorLine: {
      get() {
        return this[this.typeLine];
      },
      set(v) {
        this[this.typeLine] = v;
      },
    },
  },

  watch: {
    // ADD A WATCHER, TO CHANGE BETWEEN LANGUAGES
    '$i18n.locale': function () {
      this.ValidationRateInMinScale = [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Months'), Scale: 40320 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Weeks'), Scale: 10080 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Days'), Scale: 1440 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Hours'), Scale: 60 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Minutes'), Scale: 1 },
      ];

      this.NominalityTriggerType = [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.GreaterThan'), Type: '>', Category: 'G' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.GreaterOrEqualThan'), Type: '>=', Category: 'G' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.LesserThan'), Type: '<', Category: 'L' },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.LesserOrEqualThan'), Type: '<=', Category: 'L' },
      ];

      this.ValueDelayInHoursScale = [
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Months'), Scale: 672 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Weeks'), Scale: 168 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Days'), Scale: 24 },
        { Name: this.$t('Dashboard.SubscribeMenu.SubscribeSettings.Hours'), Scale: 1 },
      ];
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    form_next() {
      if (this.Form.SelectedAlertType
        && this.Form.SelectedAlertType.Name === 'Scheduled'
        && this.menuStepCounter === 3) {
        this.menuStepCounter = 5;
      } else {
        this.menuStepCounter += 1;
      }
    },
    form_back() {
      if (this.Form.SelectedAlertType
          && this.Form.SelectedAlertType.Name === 'Scheduled'
          && this.menuStepCounter === 5) {
        this.menuStepCounter = 3;
      } else {
        this.menuStepCounter -= 1;
      }
    },

    async init() {
      // Dispatch necessary actions to initialize the store
      await this.$store.dispatch('subscribe/init');
      this.$store.dispatch('subscribe/initializeDashboards', this.dashboards);

      // Initialize time scales with internationalized text
      this.initializeTimeScales();

      // Set default form values
      this.initializeFormDefaults();

      // Check if we are editing a sensor or configuration
      if (this.editSensor || this.editConfig) {
        this.initializeEditedAlertData();
      } else if (!this.newAlert) {
        this.initializeSensorList();
        this.menuStepCounter = 0; // Reset flow
      }

      if (this.editSensor) {
        this.menuStepCounter = 5;
      }

      if (this.newAlert) {
        this.menuStepCounter = 1;
      }
    },

    // Function to initialize time scales with internationalized text
    initializeTimeScales() {
      this.ValidationRateInMinScale = this.ValidationRateInMinScale.map((x) => ({
        Name: x.Name,
        Scale: x.Scale,
        Text: this.$t(x.Name),
      }));
      this.ValueDelayInHoursScale = this.ValueDelayInHoursScale.map((x) => ({
        Name: x.Name,
        Scale: x.Scale,
        Text: this.$t(x.Name),
      }));
    },

    // Function to set default form values
    initializeFormDefaults() {
      // eslint-disable-next-line prefer-destructuring
      this.Form.ValidRateInMinScale = this.ValidationRateInMinScale[3];
      // eslint-disable-next-line prefer-destructuring
      this.Form.DelayScale = this.ValueDelayInHoursScale[2];
    },

    // Function to initialize data when editing an alert
    initializeEditedAlertData() {
      this.EditedAlertData = this.sensorList;
      this.Settings = JSON.parse(this.sensorList.WidgetSettings);
      this.SensorList = this.getFilteredSensors();

      this.Form.SelectedSensor = this.SensorList.find(
        (x) => x.Id === this.EditedAlertData.SensorId,
      );

      this.initializeAlertType();
      this.initializeAlertSettings();

      this.Form.ActiveFrom = datehandling.zonedTimeToUtc(this.EditedAlertData.ValidationStart);
      this.Form.ActiveTo = datehandling.zonedTimeToUtc(this.EditedAlertData.ValidationEnd);

      this.initializeFormTimes();
      this.initializeFormDetails();
      this.initializeDashboardSettings();
      this.initializeMailList();
      this.initializeAlertMode();

      this.menuStepCounter = 2; // Set flow for editing alert
    },

    // Function to get filtered sensors
    getFilteredSensors() {
      return this.Settings.user.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => ({
          Name: x.Name,
          icon: 'mdi-alpha-s-box-outline',
          Id: x.Id,
          DisplayName: x.DisplayName,
          InstrumentId: x.InstrumentId,
          InstrumentName: x.InstrumentName,
        }));
    },

    // Function to initialize alert type based on EditedAlertData
    initializeAlertType() {
      if (this.EditedAlertData.IsScheduled) {
        this.Form.SelectedAlertType = this.AlertTypeList.find((x) => x.Name === 'Scheduled');
        this.LoadValueDelayHours();
      } else if (this.hasNominalityValues()) {
        this.Form.SelectedAlertType = this.AlertTypeList.find((x) => x.Name === 'Nominality');
        this.initializeNominalitySettings();
      } else {
        this.Form.SelectedAlertType = this.AlertTypeList.find((x) => x.Name === 'Missing Data');
        this.LoadValueDelayHours();
      }

      this.LoadValidationDays();
      this.LoadValidationRateMinutes();
    },

    // Function to check if there are nominality values
    hasNominalityValues() {
      return NotNull(this.EditedAlertData.ValueLt)
      || NotNull(this.EditedAlertData.ValueLteq)
      || NotNull(this.EditedAlertData.ValueGt)
      || NotNull(this.EditedAlertData.ValueGteq);
    },

    // Function to initialize settings for nominality alerts
    initializeNominalitySettings() {
      this.AnotherComparatorCheckbox = this.EditedAlertData.IsArea;
      this.initializeColorSettings();
      this.selectedLine = this.EditedAlertData.SelectedLine || 'dashdot';
      this.LoadNominalityTrigger();
    },

    // Function to initialize color settings
    initializeColorSettings() {
      if (!this.EditedAlertData.ChosenColor) {
        this.setDefaultColors();
      } else if (this.AnotherComparatorCheckbox && this.EditedAlertData.ChosenColor.length <= 7) {
        this.selectedColor = `${this.EditedAlertData.ChosenColor}32`;
      } else if (this.AnotherComparatorCheckbox) {
        this.selectedColor = this.EditedAlertData.ChosenColor;
      } else {
        this.selectedColorLine = this.EditedAlertData.ChosenColor;
      }
    },

    // Function to set default colors
    setDefaultColors() {
      if (this.AnotherComparatorCheckbox) {
        this.selectedColor = '#FF000032';
      } else {
        this.selectedColorLine = '#FF0000';
      }
    },

    // Function to load nominality triggers
    LoadNominalityTrigger() {
      this.setNominalityTrigger('>', this.EditedAlertData.ValueGt);
      this.setNominalityTrigger('>=', this.EditedAlertData.ValueGteq);
      this.setNominalityTrigger('<', this.EditedAlertData.ValueLt);
      this.setNominalityTrigger('<=', this.EditedAlertData.ValueLteq);

      if (this.AnotherComparatorCheckbox) {
        this.setAdditionalNominalityTrigger('>', this.EditedAlertData.ValueGt);
        this.setAdditionalNominalityTrigger('>=', this.EditedAlertData.ValueGteq);
        this.setAdditionalNominalityTrigger('<', this.EditedAlertData.ValueLt);
        this.setAdditionalNominalityTrigger('<=', this.EditedAlertData.ValueLteq);
      }
    },

    // Utility function to set nominality trigger
    setNominalityTrigger(type, value) {
      if (!this.Form.NominalityTrigger && NotNull(value)) {
        this.Form.NominalityTrigger = this.NominalityTriggerType.find((x) => x.Type === type);
        this.Form.NominalityTriggerValue = value;
      }
    },

    // Utility function to set additional nominality trigger
    setAdditionalNominalityTrigger(type, value) {
      if (this.Form.NominalityTrigger && NotNull(value)) {
        this.Form.MoreNominalityTrigger = this.NominalityTriggerType.find((x) => x.Type === type);
        this.Form.MoreNominalityTriggerValue = value;
      }
    },

    // Function to load validation days
    LoadValidationDays() {
      const ValidDaysInt = this.EditedAlertData.ValidationDays;
      for (let i = 0; i < 7; i += 1) {
        // Use bitwise operation to set valid days
        // eslint-disable-next-line no-bitwise
        this.Form.ValidDay[i] = String(ValidDaysInt & (1 << i));
      }
    },

    // Function to load validation rate in minutes
    LoadValidationRateMinutes() {
      const value = this.EditedAlertData.ValidationRateMinutes;
      for (let i = 0; i < this.ValidationRateInMinScale.length; i += 1) {
        if (value % this.ValidationRateInMinScale[i].Scale === 0) {
          this.Form.ValidRateInMinScale = this.ValidationRateInMinScale[i];
          this.Form.ValidRateInMinValue = String(value / this.ValidationRateInMinScale[i].Scale);
          return;
        }
      }
    },

    // Function to load value delay in hours
    LoadValueDelayHours() {
      if (this.EditedAlertData.ValueDelayHours) {
        const value = this.EditedAlertData.ValueDelayHours;
        for (let i = 0; i < this.ValueDelayInHoursScale.length; i += 1) {
          if (value % this.ValueDelayInHoursScale[i].Scale === 0) {
            this.Form.DelayScale = this.ValueDelayInHoursScale[i];
            this.Form.DelayValue = String(value / this.ValueDelayInHoursScale[i].Scale);
            return;
          }
        }
      } else {
        this.Form.DelayScale = this.ValueDelayInHoursScale[this.ValueDelayInHoursScale.length - 1];
        this.Form.DelayValue = '1';
      }
    },

    // Function to initialize form times
    initializeFormTimes() {
      this.Form.timeOfDayStart = SecondsToTimeText(this.EditedAlertData.ValidationTodStart);
      this.Form.timeOfDayEnd = SecondsToTimeText(this.EditedAlertData.ValidationTodEnd);
    },

    // Function to initialize form details
    initializeFormDetails() {
      this.Form.AlertName = this.EditedAlertData.Name;
      this.Form.AlertSubject = this.EditedAlertData.Subject;
      this.Form.AlertBody = this.EditedAlertData.Body;
      this.Form.LongName = this.EditedAlertData.LongName;
      this.Form.SensorAlertName = this.EditedAlertData.SensorAlertName;
    },

    // Function to initialize dashboard settings
    initializeDashboardSettings() {
      this.Form.sendFullDashboardFlag = !!this.EditedAlertData.SendFullDashboard;
      if (this.EditedAlertData.SendFullDashboard) {
        const sendObj = JSON.parse(this.EditedAlertData.SendFullDashboard);
        this.Form.sendFullDashboardFlag = sendObj.SendDashboard !== undefined
          ? sendObj.SendDashboard : true;
        this.Form.sendFullDashboardData = sendObj;
      }

      if (this.Form.sendFullDashboardData && this.Form.sendFullDashboardData.DashboardId) {
        const selectedDash = this.dashboards.find(
          (x) => x.DashboardId === this.Form.sendFullDashboardData.DashboardId,
        );
        this.Form.DashboardList = {
          Name: selectedDash.Name,
          DashboardId: selectedDash.DashboardId,
        };
      }
    },

    // Function to initialize mail list
    initializeMailList() {
      this.Form.MailList = this.mailLists.find((x) => x.MailListUid === this.EditedAlertData.MailListUid)
        || this.mailLists.find((x) => x.Name === '(None)');
    },

    // Function to initialize alert mode
    initializeAlertMode() {
      this.Form.AlertMode = this.EditedAlertData.NotificationMode || 0;

      if (this.Form.SelectedAlertType.Name === 'Scheduled') {
        this.Form.AlertPriority = -25;
      } else {
        this.Form.AlertPriority = this.EditedAlertData.NotificationPriority !== null
        && this.EditedAlertData.NotificationPriority !== undefined
          ? this.EditedAlertData.NotificationPriority : 50;
      }
    },

    // Function to initialize sensor list
    initializeSensorList() {
      this.Settings = JSON.parse(this.sensorList.WidgetSettings);
      this.SensorList = this.getFilteredSensors();
    },

    // Function to initialize alert settings
    initializeAlertSettings() {
      this.initializeAlertType();
      this.initializeNominalitySettings();
      this.LoadValidationDays();
      this.LoadValidationRateMinutes();
      this.LoadValueDelayHours();
    },

    async SaveChoices() {
      // Calculate the valid day mask from the form's ValidDay array
      const validDayMask = this.Form.ValidDay.reduce((acc, day) => acc + parseInt(day, 10), 0);

      // Initialize default values for alert properties
      let {
        IsScheduled,
        ValueLteq,
        ValueLt,
        ValueGteq,
        ValueGt,
        ValueDelayHours,
        SendFullDashboard,
        SelectedLine,
        ChosenColor,
        IsArea,
      } = this.initializeAlertValues();

      // Get the validation rate in minutes
      let ValidationRateMinutes = this.getValidationRateMinutes();

      // Handle different alert types and update relevant values
      if (this.Form.SelectedAlertType.Name === 'Nominality') {
        (
          {
            ChosenColor,
            SelectedLine,
            ValueLteq,
            ValueLt,
            ValueGteq,
            ValueGt,
            IsArea,
          } = this.handleNominalityAlertType());
      } else if (this.Form.SelectedAlertType.Name === 'Missing Data') {
        ValueDelayHours = this.getValueDelayHours();
      } else if (this.Form.SelectedAlertType.Name === 'Scheduled') {
        ({ IsScheduled, ValueDelayHours, ValidationRateMinutes } = this.handleScheduledAlertType());
      }

      // Determine dashboard settings if applicable
      SendFullDashboard = this.getSendFullDashboard();

      // Cap the maximum validation rate in minutes to 525950
      if (ValidationRateMinutes > 525950) {
        ValidationRateMinutes = 525950;
      }

      // Save or update the alert configuration based on whether editing or not
      if (this.newAlert) {
        await this.createNewAlert(
          validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
          IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
        );
      }
      if (!this.editSensor && !this.editConfig) {
        await this.createNewAlertFromDashboard(
          validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
          IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
        );
      } else if (this.editSensor) {
        await this.updateSensorAlert(
          validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
          IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
        );
      } else {
        await this.updateConfigAlert(
          validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
          IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
        );
      }
    },

    // Initialize default values for alert properties
    initializeAlertValues() {
      return {
        IsScheduled: false,
        ValueLteq: null,
        ValueLt: null,
        ValueGteq: null,
        ValueGt: null,
        ValueDelayHours: null,
        SendFullDashboard: null,
        SelectedLine: null,
        ChosenColor: null,
        IsArea: false,
      };
    },

    // Calculate the validation rate in minutes
    getValidationRateMinutes() {
      return parseInt(this.Form.ValidRateInMinValue, 10) * this.Form.ValidRateInMinScale.Scale;
    },

    // Handle settings specific to the 'Nominality' alert type
    handleNominalityAlertType() {
      let ChosenColor = this.selectedColorLine;
      const SelectedLine = this.selectedLine;
      let ValueGt = null;
      let ValueGteq = null;
      let ValueLt = null;
      let ValueLteq = null;
      let IsArea = false;

      // Set values based on the nominality trigger type
      switch (this.Form.NominalityTrigger.Type) {
        case '>': ValueGt = parseFloat(this.Form.NominalityTriggerValue, 10); break;
        case '>=': ValueGteq = parseFloat(this.Form.NominalityTriggerValue, 10); break;
        case '<': ValueLt = parseFloat(this.Form.NominalityTriggerValue, 10); break;
        case '<=': ValueLteq = parseFloat(this.Form.NominalityTriggerValue, 10); break;
        default: break;
      }

      // Adjust values if another comparator checkbox is checked
      if (this.AnotherComparatorCheckbox) {
        ChosenColor = this.selectedColor;
        IsArea = true;
        switch (this.Form.MoreNominalityTrigger.Type) {
          case '>': ValueGt = parseFloat(this.Form.MoreNominalityTriggerValue, 10); break;
          case '>=': ValueGteq = parseFloat(this.Form.MoreNominalityTriggerValue, 10); break;
          case '<': ValueLt = parseFloat(this.Form.MoreNominalityTriggerValue, 10); break;
          case '<=': ValueLteq = parseFloat(this.Form.MoreNominalityTriggerValue, 10); break;
          default: break;
        }
      }

      return {
        ChosenColor, SelectedLine, ValueLteq, ValueLt, ValueGteq, ValueGt, IsArea,
      };
    },

    // Get the delay hours for 'Missing Data' alert type
    getValueDelayHours() {
      return parseInt(this.Form.DelayValue, 10) * this.Form.DelayScale.Scale;
    },

    // Handle settings specific to the 'Scheduled' alert type
    handleScheduledAlertType() {
      const IsScheduled = true;
      const ValueDelayHours = this.getValueDelayHours();
      const ValidationRateMinutes = ValueDelayHours * 60;
      this.Form.AlertPriority = 0;
      this.Form.AlertMode = 0;

      return { IsScheduled, ValueDelayHours, ValidationRateMinutes };
    },

    // Determine the dashboard settings based on the form's dashboard list
    getSendFullDashboard() {
      if (this.Form.DashboardList) {
        return {
          DashboardId: this.Form.DashboardList.DashboardId || null,
          Kind: 'pdf',
          TemplateName: 'Dashboard',
          SendDashboard: !!this.Form.DashboardList.DashboardId,
        };
      }
      return null;
    },

    async createNewAlert(
      validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
      IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
    ) {
      try {
        await this.$store.dispatch('subscribe/createFromTable', {
          Name: this.Form.AlertName,
          LongName: this.Form.LongName,
          IsScheduled,
          ValueDelayHours,
          ValueLteq,
          ValueLt,
          ValueGteq,
          ValueGt,
          MailListUid: GetMailListUid(this.Form.MailList),
          Subject: this.Form.AlertSubject,
          Body: this.Form.AlertBody,
          ValidationLastTime: null,
          ValidationDays: validDayMask,
          ValidationStart: this.Form.ActiveFrom,
          ValidationEnd: this.Form.ActiveTo,
          ValidationTodStart: TimeTextToSeconds(this.Form.timeOfDayStart),
          ValidationTodEnd: TimeTextToSeconds(this.Form.timeOfDayEnd),
          ValidationRateMinutes,
          SendFullDashboard: JSON.stringify(SendFullDashboard),
          ChosenColor,
          SelectedLine,
          IsArea,
          NotificationMode: this.Form.AlertMode,
          NotificationPriority: this.Form.AlertPriority,
          AggregateForEvery: this.Form.AggregateForEvery,
          AggregateMultiplier: this.Form.AggregateMultiplier,
          AggregateUseTimeZone: this.Form.AggregateUseTimeZone,
          AggregateMethod: this.Form.AggregationValue,
          Timezone: this.timezone,
        });
        this.$emit('close-subscribe-from-table');
      } catch (err) {
        if (err.response?.data?.exception) {
          this.showError(err.response.data.exception);
        }
      }
    },

    // Create a new alert from dashboard selection configuration
    async createNewAlertFromDashboard(
      validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
      IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
    ) {
      try {
        await this.$store.dispatch('subscribe/createFromDashboard', {
          Name: this.Form.AlertName,
          LongName: this.Form.LongName,
          SensorAlertName: this.Form.SensorAlertName,
          IsScheduled,
          ValueDelayHours,
          ValueLteq,
          ValueLt,
          ValueGteq,
          ValueGt,
          InstrumentName: this.Form.SelectedSensor.InstrumentName,
          SensorName: this.Form.SelectedSensor.Name,
          WidgetSettings: JSON.stringify(this.Settings),
          WidgetType: '',
          MailListUid: GetMailListUid(this.Form.MailList),
          Subject: this.Form.AlertSubject,
          Body: this.Form.AlertBody,
          ValidationLastTime: null,
          ValidationDays: validDayMask,
          ValidationStart: this.Form.ActiveFrom,
          ValidationEnd: this.Form.ActiveTo,
          ValidationTodStart: TimeTextToSeconds(this.Form.timeOfDayStart),
          ValidationTodEnd: TimeTextToSeconds(this.Form.timeOfDayEnd),
          ValidationRateMinutes,
          SensorId: parseInt(this.Form.SelectedSensor.Id, 10),
          SendFullDashboard: JSON.stringify(SendFullDashboard),
          ChosenColor,
          SelectedLine,
          IsArea,
          NotificationMode: this.Form.AlertMode,
          NotificationPriority: this.Form.AlertPriority,
        });
        this.RefreshGraph();
        this.$emit('close-subscribe');
      } catch (err) {
        if (err.response?.data?.exception) {
          this.showError(err.response.data.exception);
        }
      }
    },

    // Update the alert configuration for a specific sensor
    async updateSensorAlert(
      validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
      IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
    ) {
      try {
        const alertListReturn = await this.$store.dispatch('subscribe/updateForSensor', {
          BasicAlertConfigId: this.EditedAlertData.BasicAlertConfigId,
          SensorId: this.EditedAlertData.SensorId,
          Name: this.Form.AlertName,
          LongName: this.Form.LongName,
          SensorAlertName: this.Form.SensorAlertName,
          IsScheduled,
          ValueDelayHours,
          ValueLteq,
          ValueLt,
          InstrumentName: this.Form.SelectedSensor.InstrumentName,
          SensorName: this.Form.SelectedSensor.Name,
          ValueGteq,
          ValueGt,
          MailListUid: GetMailListUid(this.Form.MailList),
          Subject: this.Form.AlertSubject,
          Body: this.Form.AlertBody,
          ValidationLastTime: null,
          ValidationDays: validDayMask,
          ValidationStart: this.Form.ActiveFrom,
          ValidationEnd: this.Form.ActiveTo,
          ValidationTodStart: TimeTextToSeconds(this.Form.timeOfDayStart),
          ValidationTodEnd: TimeTextToSeconds(this.Form.timeOfDayEnd),
          ValidationRateMinutes,
          SendFullDashboard: JSON.stringify(SendFullDashboard),
          ChosenColor,
          SelectedLine,
          IsArea,
          NotificationMode: this.Form.AlertMode,
          NotificationPriority: this.Form.AlertPriority,
        });

        const returnData = alertListReturn.data;
        const sensorAnswer = returnData[0].BasicAlertConfigSensors.find(
          (x) => x.SensorId === this.EditedAlertData.SensorId,
        );

        this.$emit('close-subscribe', this.formatEmitData(returnData[0], sensorAnswer));
      } catch (err) {
        if (err.response?.data?.exception) {
          this.showError(err.response.data.exception);
        }
      }
    },

    formatEmitData(alertData, sensorAnswer) {
      return {
        BasicAlertConfigId: alertData.BasicAlertConfigId,
        Body: alertData.Body,
        ChosenColor: alertData.ChosenColor,
        InstrumentName: sensorAnswer.InstrumentName,
        IsArea: alertData.IsArea,
        IsScheduled: sensorAnswer.IsScheduled,
        MailListUid: alertData.MailListUid,
        Name: alertData.Name,
        LongName: alertData.LongName,
        NotificationMode: alertData.NotificationMode,
        NotificationPriority: alertData.NotificationPriority,
        Recipients: alertData.Recipients,
        SelectedLine: alertData.SelectedLine,
        SendFullDashboard: sensorAnswer.SendFullDashboard,
        SensorAlertName: sensorAnswer.SensorAlertName,
        SensorId: sensorAnswer.SensorId,
        SensorName: sensorAnswer.SensorName,
        StateFlag: sensorAnswer.StateFlag,
        StateTimeStamp: sensorAnswer.StateTimeStamp,
        StateValue: sensorAnswer.StateValue,
        Subject: alertData.Subject,
        UserId: alertData.UserId,
        ValidationCursor: alertData.ValidationCursor,
        ValidationDays: alertData.ValidationDays,
        ValidationEnd: alertData.ValidationEnd,
        ValidationLastTime: alertData.ValidationLastTime,
        ValidationRateMinutes: alertData.ValidationRateMinutes,
        ValidationStart: alertData.ValidationStart,
        ValidationTodEnd: alertData.ValidationTodEnd,
        ValidationTodStart: alertData.ValidationTodStart,
        ValueDelayHours: sensorAnswer.ValueDelayHours,
        ValueGt: sensorAnswer.ValueGt,
        ValueGteq: sensorAnswer.ValueGteq,
        ValueLt: sensorAnswer.ValueLt,
        ValueLteq: sensorAnswer.ValueLteq,
        ViolationMessage: sensorAnswer.ViolationMessage,
        ViolationMessageTs: sensorAnswer.ViolationMessageTs,
        ViolationTs: sensorAnswer.ViolationTs,
        WidgetAuthorization: sensorAnswer.WidgetAuthorization,
        WidgetSettings: sensorAnswer.WidgetSettings,
        WidgetType: sensorAnswer.WidgetType,
        sharedAccessGroups: alertData.sharedAccessGroups,
        sharedUsers: alertData.sharedUsers,
      };
    },

    // Update the alert configuration for multiple sensors
    async updateConfigAlert(
      validDayMask, ValidationRateMinutes, SendFullDashboard, ChosenColor, SelectedLine, IsArea,
      IsScheduled, ValueDelayHours, ValueLteq, ValueLt, ValueGteq, ValueGt,
    ) {
      let answer;
      try {
        // Dispatch update action for the sensor
        const alertListReturn = await this.$store.dispatch('subscribe/updateConfig', {
          BasicAlertConfigId: this.EditedAlertData.BasicAlertConfigId,
          Name: this.Form.AlertName,
          LongName: this.Form.LongName,
          IsScheduled,
          ValueDelayHours,
          ValueLteq,
          ValueLt,
          ValueGteq,
          ValueGt,
          MailListUid: GetMailListUid(this.Form.MailList),
          Subject: this.Form.AlertSubject,
          Body: this.Form.AlertBody,
          ValidationLastTime: null,
          ValidationDays: validDayMask,
          ValidationStart: this.Form.ActiveFrom,
          ValidationEnd: this.Form.ActiveTo,
          ValidationTodStart: TimeTextToSeconds(this.Form.timeOfDayStart),
          ValidationTodEnd: TimeTextToSeconds(this.Form.timeOfDayEnd),
          ValidationRateMinutes,
          ChosenColor,
          SelectedLine,
          IsArea,
          NotificationMode: this.Form.AlertMode,
          NotificationPriority: this.Form.AlertPriority,
        });

        answer = alertListReturn.data;
        this.$emit('close-subscribe', answer);
      } catch (err) {
        if (err.response?.data?.exception) {
          this.showError(err.response.data.exception);
        }
      }
    },

    showError(msg) {
      this.error.Message = msg;
      this.error.Show = true;
    },

    RefreshGraph() {
      this.$emit('save', {
        DashboardsWidgetId: this.sensorList.DashboardsWidgetId,
        WidgetSettings: this.sensorList.WidgetSettings,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .right-sidebar {
    position: absolute;
    top: 49px;
    right: 10px;
    width: 400px;
    z-index: 9999;
  }
  .checkbox-container {
    display: flex;
    justify-content: space-between;
  }
</style>
