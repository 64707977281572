import api from '@/api/importal';
import datehandling from '@/components/datehandling';
import store from '@/store';

const state = {
  dashboardWidgets: [],
  activeWidgetId: null,
  visualisationSettingsAllowed: true,
};

const getters = {
  activeWidget(state) {
    const filteredWidgets = state.dashboardWidgets
      .filter((widget) => widget.DashboardsWidgetId === state.activeWidgetId);

    if (filteredWidgets) {
      return filteredWidgets[0];
    }

    return null;
  },
  LastPicker: () => ['Years', 'Months', 'Days', 'Hours', 'Minutes'],
  LastPicker2Query: () => ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
  AggregateForEveryItems: () => ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
  AggregateForEvery2Query: () => ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
};

const actions = {
  async list({ commit }, payload) {
    if (payload.dashId) {
      const response = await api.get('DashboardWidgetList', { params: payload });
      commit('dashboardWidgets', response.data);
    } else {
      commit('dashboardWidgets', []);
    }
  },
  async create({ commit }, payload) {
    await api.post('DashboardWidgetNew', payload);
    commit('createWidget', payload);
  },
  async saveAxis({ commit }, payload) {
    const response = await api.post('DashboardWidgetUpdateAxis', payload);
    commit('dashboardWidgets', response.data);
  },
  async saveSourceSettings(state, payload) {
    await api.post('DashboardWidgetUpdateDataSourceSettings', payload);
  },
  async saveWidgetSettings({ commit }, payload) {
    await api.post('DashboardWidgetUpdateWidgetSettings', payload);
    commit('saveWidgetSettings', payload);
  },
  async remove(state, payload) {
    await api.delete('DashboardWidgetRemove', { data: payload });
  },
  async resetWidgets({ commit }) {
    commit('dashboardWidgets', []);
  },
  updateDateRangeAndAggregation({ getters }, payload) {
    const { widgetSettings } = payload;
    if (!widgetSettings.data) {
      return;
    }

    const { visualisationSubType } = payload;
    const globalDateRange = store.getters['app/globalDateRange'];

    // Date range
    if (widgetSettings.data.input_filter) {
      if (!widgetSettings.user.input_filter) {
        widgetSettings.user.input_filter = widgetSettings.data.input_filter;
      }

      widgetSettings.data.input_filter = [];
      if (globalDateRange.activated) {
        if (globalDateRange.dateRange.periodicity === 'last_n') {
          widgetSettings.data.input_filter.push({
            last_n: {
              scale: getters.LastPicker2Query[getters.LastPicker.indexOf(globalDateRange.dateRange.lastN.scale)],
              period: Number(globalDateRange.dateRange.lastN.period),
            },
          });
        } else {
          widgetSettings.data.input_filter.push({
            time_range: {
              start: datehandling.formatForApi(globalDateRange.dateRange.range.start),
              end: datehandling.formatForApi(globalDateRange.dateRange.range.end),
            },
          });
        }
      } else {
        widgetSettings.data.input_filter.push(widgetSettings.user.input_filter[0]);
      }
    }

    // Aggregate by
    if (widgetSettings.data.input_time_axis && !visualisationSubType.startsWith('vector+fourier+')) {
      if (!widgetSettings.user.input_time_axis) {
        widgetSettings.user.input_time_axis = {
          round_to: {
            scale: widgetSettings.data.input_time_axis.round_to.scale,
            multiplier: widgetSettings.data.input_time_axis.round_to.multiplier,
            divisor: widgetSettings.data.input_time_axis.round_to.divisor,
          },
        };
      }

      if (globalDateRange.activated) {
        widgetSettings.data.input_time_axis = {
          round_to: {
            scale: (globalDateRange.aggregateUseTimeZone ? 'TZ' : '') + getters.AggregateForEvery2Query[getters.AggregateForEveryItems.indexOf(globalDateRange.aggregateBy.scale)],
            multiplier: Number(globalDateRange.aggregateBy.multiplier),
            divisor: Number(globalDateRange.aggregateBy.multiplier),
          },
        };
      } else {
        widgetSettings.data.input_time_axis = widgetSettings.user.input_time_axis;
      }
    }
  },
};

const mutations = {
  createWidget(state, payload) {
    state.dashboardWidgets.push(payload);
  },
  saveWidgetSettings(state, payload) {
    const filteredWidgets = state.dashboardWidgets
      .filter((widget) => widget.DashboardsWidgetId === payload.DashboardsWidgetId);

    if (filteredWidgets && filteredWidgets.length > 0) {
      filteredWidgets[0].WidgetSettings = payload.WidgetSettings;
    } else {
      state.dashboardWidgets.push(payload);
    }
  },
  dashboardWidgets(state, payload) {
    state.dashboardWidgets = payload;
  },
  activeWidgetId(state, payload) {
    state.activeWidgetId = payload;
  },
  visualisationSettingsAllowed(state, payload) {
    state.visualisationSettingsAllowed = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
