import ModelsRoutes from './models/models.routes';
import EquipmentsRoutes from './equipments/equipments.routes';
import PENumbersRoutes from './penumbers/penumbers.routes';
import AnchorTypesRoutes from './anchortypes/anchortypes.routes';
import CellularAccountProviders from './cellularaccountproviders/cellularaccountproviders.routes';

export default [
  ...ModelsRoutes,
  ...EquipmentsRoutes,
  ...PENumbersRoutes,
  ...AnchorTypesRoutes,
  ...CellularAccountProviders,
];
