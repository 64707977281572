import SiteDetailsAndTimelineModal from './components/SiteDetailsAndTimelineModal.vue';
import SitesList from './components/SitesList.vue';

export default [
  {
    path: '/customers/sites',
    name: 'Customers.Sites',
    component: SitesList,
    children: [
      {
        path: '/customers/sites/:id/details',
        name: 'Customers.Sites.DetailsAndTimeline',
        component: SiteDetailsAndTimelineModal,
        props: true,
      },
    ],
  },
];
