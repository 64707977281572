import i18n from '@/plugins/i18n';
import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  mailLists: [],
  dashboardsWNone: [],
};

const actions = {
  async init({ commit }) {
    return withWait({ commit }, () => api.get('AlertMailListList'))
      .then((resp) => commit('setMailList', resp.data));
  },
  async initializeDashboards({ commit }, payload) {
    commit('setDashList', payload);
  },
  async create({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicNew', payload));
  },
  async createFromDashboard({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicNew2', payload));
  },
  async createFromTable({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicNewFromTable', payload));
  },
  async update({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertBasicUpdate', payload));
  },
  async updateForSensor({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertUpdateForSensor', payload));
  },
  async updateConfig({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertUpdateConfig', payload));
  },
  async add({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertAddNewSensor', payload));
  },
  async updateEmailSettings({ commit }, payload) {
    return withWait({ commit }, () => api.post('AlertEmailSettingsUpdate', payload));
  },
};

const mutations = {
  setMailList(state, mails) {
    state.mailLists = [{
      Name: i18n.t('(None)'),
      MailListUid: undefined,
    }].concat(...mails);
  },
  setDashList(state, dashs) {
    state.dashboardsWNone = [{
      Name: i18n.t('(None)'),
      DashboardId: undefined,
    }].concat(...dashs);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
