<template>
  <v-dialog
    v-model="show"
    persistent
    width="600"
  >
    <v-card tile>
      <v-toolbar>
        <v-toolbar-title>{{ $t('Dashboard.LeafletWidget.PlaceInstrument') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <select-with-search
          :value="instrument"
          :search-value="searchInstrument"
          :items="instruments"
          item-text="DisplayName"
          :has-more-items="hasMoreInstruments"
          :label="$t('Dashboard.Instrument')"
          class="mb-5"
          :on-changed="(value) => instrument = value"
          :on-searched="(value) => onInstrumentSearched(value)"
          :on-more="() => getInstruments()"
        />
        <v-text-field
          v-model="latitude"
          type="number"
          :label="$t('Dashboard.LeafletWidget.Latitude')"
        />
        <v-text-field
          v-model="longitude"
          type="number"
          :label="$t('Dashboard.LeafletWidget.Longitude')"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          :disabled="!latitude || !longitude || !instrument"
          @click="submitClick"
        >
          {{ $t('Submit') }}
        </v-btn>
        <v-btn
          text
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import importal from '@/api/importal';
import SelectWithSearch from '@/components/SelectWithSearch.vue';

export default {
  components: {
    SelectWithSearch,
  },
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'latitude', event: 'models:lat' },
    { data: 'longitude', event: 'models:lon' },
    { data: 'instrument', event: 'models:instrument' },
  ],
  props: {
  },
  emits: ['click:submit', 'search'],
  data: () => ({
    show: false,
    latitude: 0.0,
    longitude: 0.0,
    instrument: null,

    searchInstrument: '',
    instruments: [],
    count: 20,
    hasMoreInstruments: false,
  }),
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    longitude: function w(newVal) { this.$emit('models:lon', newVal); },
    latitude: function w(newVal) { this.$emit('models:lat', newVal); },
    instrument: function w(newVal) { this.$emit('models:instrument', newVal); },
  },
  async mounted() {
    await this.getInstruments();
  },
  methods: {
    submitClick() {
      this.$emit('click:submit', {
        latitude: this.latitude,
        longitude: this.longitude,
        instrument: this.instrument,
      });
    },
    async getInstruments() {
      const instruments = await (await importal.get('InstrumentList?'
            + `&so=${this.instruments.length}&sc=${this.count}`
            + `&searchInstrument=${encodeURIComponent(this.searchInstrument || '')}`)).data;

      this.hasMoreInstruments = instruments.length === this.count;
      this.instruments.push(...instruments);
    },
    async onInstrumentSearched(value) {
      // console.log('onInstrumentSearched');
      // console.log(value);
      this.searchInstrument = value;
      this.instruments = [];
      await this.getInstruments();
    },
  },
};
</script>

<style scoped>

</style>
