<template>
  <div style="height: 100%; width: 100%">
    <auto-form-popup
      v-model="popup.Show"
      :model="popup.Model"
      :schema="popup.Schema"
      @on-accept="onPopupAccept"
    />
    <server-side-data-grid
      :grid-source="sources.DataSource"
      :show-new-button="$auth.roles.includes('Web.Gateway.Manage')"
      :refresh="refresh"
    />
  </div>
</template>

<script>
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import AutoFormPopup from '@/components/AutoFormPopup.vue';
import DataSource from './gridsources/gsattributes';

export default {
  components: {
    ServerSideDataGrid,
    AutoFormPopup,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    selectedVector: null,
    sources: {
      DataSource: null,
    },
    popup: {
      api: null,
      Show: false,
      Model: {},
      Schema: {},
    },
  }),
  created() {
    this.sources.DataSource = new DataSource(this, {
      onEdit: (api, data) => this.onEdit(api, data),
    });
  },
  methods: {
    async onEdit(api, data) {
      const d = await this.sources.DataSource.updateItemFormDefinition();
      this.popup.Model = {
        DataGatewayAttributeId: data.DataGatewayAttributeId,
        ProtocolCd: data.ProtocolCd,
        Name: data.Name,
        Value: data.ValueStr,
      };
      this.popup.Schema = d.Schema;
      this.popup.api = api;
      this.popup.Show = true;
    },
    async onPopupAccept() {
      await this.sources.DataSource.apiDevGatewayAttributeUpdate(this.popup.api, this.popup.Model);
    },
  },
};
</script>

<style lang="scss">
</style>
