import Vue from 'vue';
import * as Msal from '@azure/msal-browser';
import api from '@/api/importal';
import i18n from '@/plugins/i18n';

let instance;

export const getInstance = () => instance;

export const useMSAL = (options) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        msalInstance: null,
        isAuthenticated: false,
        user: undefined,
        error: null,
        info: null,
        authMode: null, // SSO or B2C
      };
    },
    computed: {
      roles() {
        return this.info?.listrolename || [];
      },
    },
    async created() {
      // Clean the cache looking for trash
      this.cleanupMsalCache();

      // Copy the keys on the Session Storage to Local Storage
      this.moveSessionToLocal();

      // Check for MSAL-related keys in LOCAL Storage
      const sessionKeys = Object.keys(localStorage);
      const msalKeys = sessionKeys.filter((key) => /^msal\.[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\.|$)/.test(key));

      if (msalKeys.length > 0) {
        // Try to identify the clientId from the MSAL keys
        const clientId = this.getClientIdFromMsalKeys(msalKeys);

        if (clientId) {
          if (clientId === options.ssoConfig.clientId) {
            // console.log('Authenticated with SSO');
            this.selectAuthMethodFromPreviousSession('SSO');
          } else if (clientId === options.b2cConfig.clientId) {
            // console.log('Authenticated with B2C');
            this.selectAuthMethodFromPreviousSession('B2C');
          }
        } else {
          // console.log('No valid MSAL clientId found in keys');
          this.showAuthMethodPrompt();
        }
      } else {
        // Show the Auth options if not authentificated already
        this.showAuthMethodPrompt();
      }
    },
    methods: {
      getClientIdFromMsalKeys(msalKeys) {
        // Example MSAL key format: msal.<clientId>.authority.<accountId>
        const keyWithClientId = msalKeys.find(
          (key) => /^msal\.([a-z0-9-]+)\./i.test(key) && !key.includes('interaction'),
        );

        if (keyWithClientId) {
          const matches = keyWithClientId.match(/^msal\.([a-z0-9-]+)\./i);
          return matches ? matches[1] : null; // Extract and return clientId
        }

        return null; // No matching clientId found
      },

      /**
       * Clean the MSAL trash in session location, if there is a msal without other keys
       * means that msal will block the creation of another msal
       */
      cleanupMsalCache() {
        try {
          const sessionKeys = Object.keys(localStorage);

          // Check for MSAL-related keys
          const msalKeys = sessionKeys.filter((key) => key.startsWith('msal.'));

          if (msalKeys.length === 1 && msalKeys.includes('msal.interaction.status')) {
            // If only 'msal.interaction.status' is present, it's trash, so remove it
            localStorage.removeItem('msal.interaction.status');
            // console.log('Removed stale msal.interaction.status from session storage');
          }
        } catch (error) {
          if (error) {
            // console.error('Error during logout:', error);
          }
        }
      },

      /**
       * Initialize MSAL instance based on selected auth method.
       */
      initMsalConfig(authMode) {
        const config = authMode === 'SSO' ? options.ssoConfig : options.b2cConfig;

        const msalConfig = {
          auth: {
            clientId: config.clientId,
            authority: config.authority,
            redirectUri: config.redirectUri,
            knownAuthorities: [config.authority],
            postLogoutRedirectUri: config.redirectUri,
          },
          cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
          },
          system: {
            logger: {
              loggerCallback: (logLevel, message) => {
                this.error = message;
              },
              piiLoggingEnabled: true,
              logLevel: Msal.LogLevel.Verbose,
            },
          },
        };

        this.msalInstance = new Msal.PublicClientApplication(msalConfig);
      },

      /**
       * Show authentication method prompt.
       */
      showAuthMethodPrompt() {
        const title = i18n.t('Auth.selectAuthMethod');
        const ssoLabel = i18n.t('Auth.sso');
        const userPassLabel = i18n.t('Auth.usernamePassword');

        const htmlContent = `
          <div id="auth-prompt" class="auth-prompt">
            <h2>${title}</h2>
            <button id="btn-sso">${ssoLabel}</button>
            <button id="btn-userpass">${userPassLabel}</button>
          </div>
        `;

        document.body.insertAdjacentHTML('beforeend', htmlContent);

        document.getElementById('btn-sso').addEventListener('click', () => {
          this.selectAuthMethod('SSO');
        });

        document.getElementById('btn-userpass').addEventListener('click', () => {
          this.selectAuthMethod('B2C');
        });
      },

      /**
       * Handle authentication method selection.
       */
      async selectAuthMethod(authMode) {
        this.cleanupMsalCache();

        this.authMode = authMode;
        this.initMsalConfig(authMode);

        document.getElementById('auth-prompt')?.remove();

        this.isAuthenticated = false;
        this.loginWithRedirect();
      },

      async selectAuthMethodFromPreviousSession(authMode) {
        this.authMode = authMode;
        this.initMsalConfig(authMode);

        await this.msalInstance.handleRedirectPromise().then((loginResponse) => {
          if (loginResponse) {
            this.user = loginResponse.account;
            this.msalInstance.setActiveAccount(loginResponse.account);
            api.get('/UserGetInfo').then((infoResponse) => {
              this.info = infoResponse.data;
              this.isAuthenticated = true;
            });
          } else {
            const accounts = this.msalInstance.getAllAccounts();

            if (accounts.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.user = accounts[0];
              this.msalInstance.setActiveAccount(accounts[0]);
              api.get('/UserGetInfo').then((infoResponse) => {
                this.info = infoResponse.data;
                this.isAuthenticated = true;
              });
            } else {
              this.isAuthenticated = false;
              this.showAuthMethodPrompt();
            }
          }
        }).catch((error) => {
          this.handleError(error);
        });
      },

      /**
       * Transfer the data in the session Storage to local Storage
       */
      moveSessionToLocal() {
        try {
          const sessionKeys = Object.keys(sessionStorage);

          // Identify MSAL-related keys in sessionStorage
          const msalKeysInSession = sessionKeys.filter((key) => key.startsWith('msal.'));
          if (msalKeysInSession.length === 0) {
            // console.log('No MSAL keys found in sessionStorage to move.');
            return;
          }

          // Identify MSAL-related keys in localStorage
          const localStorageKeys = Object.keys(localStorage);
          const msalKeysInLocal = localStorageKeys.filter((key) => key.startsWith('msal.'));

          // Remove only conflicting MSAL keys from localStorage
          msalKeysInLocal.forEach((key) => {
            if (msalKeysInSession.includes(key) || msalKeysInSession.length > 1) {
              localStorage.removeItem(key);
              // console.log(`Removed MSAL key from localStorage: ${key}`);
            }
          });

          // Move MSAL-related keys from sessionStorage to localStorage
          msalKeysInSession.forEach((key) => {
            const value = sessionStorage.getItem(key);
            if (value) {
              if (!localStorage.getItem(key)) {
                localStorage.setItem(key, value); // Copy to localStorage
                // console.log(`Copied MSAL key to localStorage: ${key}`);
              }
              sessionStorage.removeItem(key); // Remove from sessionStorage
            }
          });

          // console.log('MSAL keys moved from sessionStorage to localStorage and cleaned up.');
        } catch (error) {
          // console.error('Error moving MSAL keys to localStorage:', error);
        }
      },

      /**
       * Login with redirect.
       */
      loginWithRedirect() {
        const request = {
          prompt: 'select_account',
          scopes: [this.authMode === 'SSO' ? options.ssoConfig.userScope : options.b2cConfig.userScope],
        };
        this.msalInstance.loginRedirect(request).catch((error) => {
          this.handleError(error);
        });
      },

      /**
       * Logout with redirect.
       */
      logoutWithRedirect() {
        // Clear the MSAL cache manually after logout (otherwise the redirect does not clear the cache)
        this.clearCache();

        this.msalInstance.logoutRedirect().then(() => {
          // After logout completes, initiate a fresh login
          this.showAuthMethodPrompt();
        }).catch((error) => {
          if (error) {
            // console.error('Error during logout:', error);
          }
        });
      },

      clearCache() {
        try {
          const cacheStorage = this.msalInstance.getCacheStorage();

          // Clear specific cache items
          cacheStorage.remove('msal.interaction.status');
          cacheStorage.remove('msal.idtoken');
          cacheStorage.remove('msal.access.token');

          // Optionally clear all cache entries (be cautious with this)
          // cacheStorage.clear();
        } catch (error) {
          if (error) {
            // console.error('Error during logout:', error);
          }
        }
      },
      /**
       * Acquire token silently.
       */
      async acquireTokenSilent() {
        const loginRequest = {
          scopes: [this.authMode === 'SSO' ? options.ssoConfig.userScope : options.b2cConfig.userScope],
        };

        return this.msalInstance.acquireTokenSilent(loginRequest).then((tokenResponse) => {
          // Do something with the tokenResponse
          this.user = tokenResponse.account;
          return tokenResponse;
        }).catch(async (error) => {
          if (error instanceof Msal.InteractionRequiredAuthError) {
            this.isAuthenticated = false;
            this.loginWithRedirect();
          }
          return null;
        }).catch((error) => {
          this.isAuthenticated = false;
          this.error = error;
        });
      },

      async acquireApiTokenSilent() {
        const loginRequest = {
          scopes: [this.authMode === 'SSO' ? options.ssoConfig.apiScope : options.b2cConfig.apiScope],
        };

        return this.msalInstance.acquireTokenSilent(loginRequest).then((tokenResponse) => {
          // Do something with the tokenResponse
          this.user = tokenResponse.account;
          return tokenResponse;
        }).catch(async (error) => {
          if (error instanceof Msal.InteractionRequiredAuthError) {
            this.isAuthenticated = false;
            this.loginWithRedirect();
          }
          return null;
        }).catch((error) => {
          this.isAuthenticated = false;
          this.error = error;
        });
      },

      async GetToken() {
        return this.acquireTokenSilent();
      },

      /**
       * Handle authentication errors.
       */
      handleError(error) {
        this.error = error;
        if (String(error).includes('AADB2C90118')) {
          this.msalInstance.loginRedirect({
            authority: options.b2cConfig.passwordReset,
          });
        } else if (String(error).includes('AADB2C90088')) {
          this.logoutWithRedirect();
        } else {
          this.loginWithRedirect();
        }
      },
    },
  });

  return instance;
};

export default {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = useMSAL({
      ssoConfig: {
        clientId: options.clientIdSSO,
        authority: options.authoritySSO,
        redirectUri: options.redirectUriSSO,
        userScope: options.userScopeSSO,
        apiScope: options.apiScopeSSO,
      },
      b2cConfig: {
        clientId: options.clientId,
        authority: options.authority,
        redirectUri: options.redirectUri,
        userScope: options.userScope,
        apiScope: options.apiScope,
        passwordReset: options.passwordReset,
      },
    });
  },
};
