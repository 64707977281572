<template>
  <v-dialog
    persistent
    v-model="show"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>{{ site.Name }}</v-card-title>
      <v-card-text>
        <v-tabs
          v-model="activeTab"
          style="border-width:0px;"
          grow
        >
          <v-tab key="details" v-t="'Customers.Sites.DetailsAndTimeline.Details'" />
          <v-tab key="timeline" v-t="'Customers.Sites.DetailsAndTimeline.Timeline'" />
          <v-tab key="land-owners" v-t="'Customers.Sites.DetailsAndTimeline.LandOwners'" />
          <v-tab key="notes" v-t="'Customers.Sites.DetailsAndTimeline.SiteNotes'" />
          <v-tab key="maintenance" v-t="'Customers.Sites.DetailsAndTimeline.SiteMaintenance'" />
          <v-tab key="attachments" v-t="'Customers.Sites.DetailsAndTimeline.Attachments'"
            @click="getAttachments()"/>

          <v-tabs-items
            v-model="activeTab"
          >
            <v-tab-item key="details">
              <div class="d-flex" style="height: 100%">
                <SiteDetails class="col-4" />
                <SiteMap class="col-8" />
              </div>
            </v-tab-item>
            <v-tab-item key="timeline">
              <SiteTimeline />
            </v-tab-item>
            <v-tab-item key="land-owners">
              <SiteLandOwners />
            </v-tab-item>
            <v-tab-item key="notes">
              <SiteNotes />
            </v-tab-item>
            <v-tab-item key="maintenance">
              <SiteNotes maintenance-records />
            </v-tab-item>
            <v-tab-item key="attachments">
              <SiteAttachmentList/>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="navigateToList()"
          v-t="'Customers.Sites.Close'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import SiteDetails from './details/SiteDetails.vue';
import SiteLandOwners from './details/SiteLandOwners.vue';
import SiteMap from './details/SiteMap.vue';
import SiteNotes from './details/SiteNotes.vue';
import SiteTimeline from './details/SiteTimeline.vue';

export default {
  components: {
    SiteDetails,
    SiteLandOwners,
    SiteMap,
    SiteNotes,
    SiteTimeline,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activeTab: 0,
      show: false,
    };
  },
  computed: {
    ...mapState('customers/sites', ['site']),
  },
  async created() {
    await this.getDetails();
  },
  methods: {
    async getDetails() {
      await this.$store.dispatch('customers/sites/getByGuid', this.id);
      this.show = true;
    },
    navigateToList() {
      this.show = false;
      this.$router.push({ name: 'Customers.Sites' });
    },
    async getAttachments() {
      await this.$store.dispatch('site/siteAttachments/setSiteGuid', this.id);
    },
  },
};
</script>

<style scoped>
.v-window-item {
  min-height: 512px !important;
  padding-top: 12px;
}
</style>
