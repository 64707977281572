<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <server-side-data-grid
      :show-new-button="false"
      :grid-source="sources.GsAlertStatus"
      :refresh="refresh"
      :row-data-init="displayedRows"
      :toolbar-buttons="toolbarButtons"
      :toolbar-fields="toolbarFields"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import GsAlertStatus from './gridsources/gsalertstatus';
import FieldTypesEnum from '@/components/FieldTypesEnum';

function NotNull(x) {
  return x !== null && x !== undefined;
}

export default {
  name: 'AlertStatus',
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        GsAlertStatus: null,
      },

      StateFlags: [
        'Inactif', 'Actif', 'InactifAndAck', 'ActifAndAck', 'MuteAndInactif', 'MuteAndActif', 'MuteAndAckAndInactif', 'MuteAndAckAndActif',
        'InactifAndDeferred', 'ActifAndDeferred', 'InactifAndAckAndDeferred', 'ActifAndAckAndDeferred', 'MuteAndInactifAndDeferred',
        'MuteAndActifAndDeferred', 'MuteAndAckAndInactifAndDeferred', 'MuteAndAckAndActifAndDeferred',
      ],

      refresh: 0,
      isInitializing: false,

      selectedTypes: [],

      count: 20,
      instruments: [],
      sensors: [],
      alerts: [],

      form: {
        searchInstrument: null,
        selectedInstrument: null,
        hasMoreInstruments: true,
        searchSensor: null,
        selectedSensor: null,
        hasMoreSensors: false,
        selectedAlert: null,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
    ...mapState('alert', ['journalRowdata']),
    ...mapGetters('alert', ['FunctionIdTypes']),

    alertDefinitionRoute() {
      let route = { name: 'Alert.Manage' };
      if (this.form.selectedInstrument && this.form.selectedSensor) {
        route = {
          name: 'Alert.Manage.SearchInstrumentSensor',
          params: { searchInstrument: this.form.selectedInstrument.Name, searchSensor: this.form.selectedSensor.Name },
        };
      } else if (this.form.selectedInstrument) {
        route = {
          name: 'Alert.Manage.SearchInstrument',
          params: { searchInstrument: this.form.selectedInstrument.Name },
        };
      } else if (this.form.selectedSensor) {
        route = {
          name: 'Alert.Manage.SearchSensor',
          params: { searchSensor: this.form.selectedSensor.Name },
        };
      }

      return route;
    },

    displayedRows() {
      return this.journalRowdata
        .filter((item) => !this.selectedTypes.length
          || this.selectedTypes.map((x) => x.type).includes(item.Type));
    },

    displayedFunctionIdsTypes() {
      const types = this.FunctionIdTypes;
      types.sort((a, b) => a.name.localeCompare(b.name));
      return types;
    },

    toolbarButtons() {
      return [
        {
          tooltip: this.$t('Refresh'),
          icon: 'mdi-refresh',
          enabled: true,
          click: () => { this.onRefresh(); },
        },
        {
          tooltip: this.$t('Alert.AlertStatus.ViewToAlertsDefinition'),
          icon: 'mdi-bell-outline',
          enabled: true,
          click: () => { this.$router.push(this.alertDefinitionRoute); },
        },
        {
          tooltip: this.$t('Alert.AlertStatus.ViewToManageAlert'),
          icon: 'mdi-cellphone-wireless',
          enabled: !!this.form.selectedAlert,
          click: () => { this.$router.push({ name: 'Alert.ManageAlert', params: { id: this.form.selectedAlert.AlertId } }); },
        },
      ];
    },

    toolbarFields() {
      return [
        {
          fieldType: FieldTypesEnum.SelectWithSearch,
          value: this.form.selectedInstrument,
          searchValue: this.form.searchInstrument,
          label: this.$t('Alert.Manage.AlertList.InstrumentName'),
          items: this.instruments,
          itemText: 'DisplayName',
          hasMoreItems: this.form.hasMoreInstruments,
          error: !this.isInitializing && !this.form.selectedInstrument,
          width: '200px',
          onChanged: (value) => { this.onSelectedInstrument(value); },
          onSearched: (value) => { this.onSearchInstrument(value); },
          onMore: () => { this.getInstruments(); },
        },
        {
          fieldType: FieldTypesEnum.SelectWithSearch,
          value: this.form.selectedSensor,
          searchValue: this.form.searchSensor,
          label: this.$t('Alert.Manage.AlertList.SensorName'),
          items: this.sensors,
          itemText: 'DisplayName',
          hasMoreItems: this.form.hasMoreSensors,
          disabled: !this.form.selectedInstrument,
          error: !this.isInitializing && this.form.selectedInstrument && !this.form.selectedSensor,
          width: '200px',
          onChanged: (value) => { this.onSelectedSensor(value); },
          onSearched: (value) => { this.onSearchSensor(value); },
          onMore: () => { this.getSensors(); },
        },
        {
          fieldType: FieldTypesEnum.SelectField,
          value: this.form.selectedAlert,
          label: this.$t('Alert.Manage.AlertList.SensorAlert'),
          items: this.alerts,
          itemText: 'AlertName',
          disabled: !this.form.selectedSensor,
          width: '200px',
          onChanged: (value) => { this.onSelectedAlert(value); },
          onClear: () => { this.onSelectedAlert(null); },
        },
        {
          fieldType: FieldTypesEnum.SelectField,
          value: this.selectedTypes,
          label: this.$t('Alert.Manage.AlertList.Type'),
          items: this.displayedFunctionIdsTypes,
          itemText: 'name',
          width: '200px',
          multiple: true,
          onChanged: (value) => { this.selectedTypes = value; },
        },
      ];
    },
  },
  watch: {
    async tenantId() {
      this.onRefresh();
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.onRefresh();
      }
    },
  },
  created() {
    this.sources.GsAlertStatus = new GsAlertStatus(this, {
      getStateFlag: (value) => this.getStateFlag(value),
    });
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async onRefresh() {
      this.refresh += 1;
      await this.init();
    },
    async init() {
      this.isInitializing = true;
      this.$store.commit('alert/setJournalRowData', []);

      const { sensorId, alertId } = this.$route.params;

      await this.getInstruments()
        .then(async () => {
          if (sensorId) {
            this.form.selectedSensor = await (await importal.get(`Sensor?id=${sensorId}`)).data;

            if (!this.form.selectedSensor) {
              this.$router.replace({ name: 'Alert.Status' });
              return;
            }

            this.form.selectedInstrument = this.instruments
              .find((x) => x.Id === this.form.selectedSensor.InstrumentId);

            if (!this.form.selectedInstrument) {
              this.form.selectedInstrument = await (await importal
                .get(`Instrument?id=${this.form.selectedSensor.InstrumentId}`)).data;
            }

            if (this.form.selectedInstrument) {
              await this.getSensors();
            }

            await this.getSensorAlerts().then(() => {
              if (alertId) {
                this.form.selectedAlert = this.alerts
                  .find((x) => x.AlertId.toString() === alertId.toString());
              }
            });

            await this.getAlerts(this.form.selectedSensor.Id, alertId);
          }
        })
        .finally(() => {
          this.isInitializing = false;
        });
    },
    async getInstruments() {
      const instruments = await (await importal.get('InstrumentList?'
            + `&so=${this.instruments.length}&sc=${this.count}`
            + `&searchInstrument=${encodeURIComponent(this.form.searchInstrument || '')}`)).data;

      this.form.hasMoreInstruments = instruments.length === this.count;
      this.instruments.push(...instruments);
    },
    async getSensors() {
      const sensors = await (await importal.get('SensorListForInstrument?'
          + `so=${this.sensors.length}&sc=${this.count}`
          + `&vo=0&vc=0&searchSensor=${encodeURIComponent(this.form.searchSensor || '')}`
          + `&instrumentId=${encodeURIComponent(this.form.selectedInstrument.Id)}`)).data;

      this.form.hasMoreSensors = sensors.length === this.count;
      this.sensors.push(...sensors);
    },
    async getAlerts(sensorId, alertId) {
      await this.$store.dispatch('alert/journal', { SensorId: sensorId, AlertId: alertId });
    },
    async getSensorAlerts() {
      this.alerts = await (await importal
        .get(`AlertsForSensor?sensorId=${this.form.selectedSensor.Id}`)).data;

      this.alerts.sort((a, b) => a.AlertName.localeCompare(b.AlertName));
    },
    getStateFlag(value) {
      return NotNull(value) ? this.$t(`Alert.AlertStatus.StateFlags.${this.StateFlags[value]}`) : '';
    },
    async onSearchInstrument(value) {
      this.form.searchInstrument = value;
      this.instruments = [];
      await this.getInstruments();
    },
    async onSelectedInstrument(value) {
      this.form.selectedInstrument = value;
      this.form.searchSensor = null;
      this.form.selectedSensor = null;
      this.sensors = [];

      this.form.selectedAlert = null;
      this.alerts = [];

      if (this.form.selectedInstrument) {
        await this.getSensors();
      }

      if (this.$route.name !== 'Alert.Status') {
        this.$router.push({ name: 'Alert.Status' });
      }
    },
    async onSearchSensor(value) {
      this.form.searchSensor = value;
      this.sensors = [];
      this.getSensors();
    },
    async onSelectedSensor(value) {
      this.form.selectedSensor = value;
      this.form.selectedAlert = null;
      this.alerts = [];

      if (this.form.selectedSensor) {
        await this.getSensorAlerts();
        await this.getAlerts(this.form.selectedSensor.Id);

        if (this.$route.params.sensorIdId !== this.form.selectedSensor.Id.toString()) {
          this.$router.push({ name: 'Alert.Status.Sensor', params: { sensorId: this.form.selectedSensor.Id } });
        }
      } else {
        this.$router.push({ name: 'Alert.Status' });
        this.$store.commit('alert/setJournalRowData', []);
      }
    },
    async onSelectedAlert(value) {
      this.form.selectedAlert = value;

      if (this.form.selectedSensor) {
        this.getAlerts(this.form.selectedSensor.Id, this.form.selectedAlert?.AlertId);

        this.$router.push(this.form.selectedAlert?.AlertId
          ? { name: 'Alert.Status.Alert', params: { sensorId: this.form.selectedSensor.Id, alertId: this.form.selectedAlert?.AlertId } }
          : { name: 'Alert.Status.Sensor', params: { sensorId: this.form.selectedSensor.Id } });
      }
    },
  },
};
</script>
