<template>
  <div>
    <div style="height: calc(100vh - 120px); width: 100%">
      <ServerSideDataGrid
        :show-new-button="$auth.roles.includes('Web.Dashboard.ModifyAlert')"
        :grid-source="sources.GsAlert"
        :refresh="refresh"
        :row-data-init="rowSensors"
        :toolbar-buttons="toolbarButtons"
        enable-selection="multiple"
        @selection-changed="onSelect"
        @onNewButtonClicked="addNewSensor"
      />
    </div>
    <AutoInfoPopup
      v-model="popupinfo.Show"
      :model="popupinfo.Model"
      :title="popupinfo.Title"
    />
    <SubscribeSetting
      v-if="showEditPopup"
      :sensor-list="selectedAlert"
      :edit-sensor="true"
      :edit-config="false"
      @close-subscribe="onEditAlert"
    />
    <v-dialog
      v-model="showPdfPopup"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ $t('Alert.Manage.AlertList.PdfIcon') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedDashboardId"
            :items="dashboardsWNone"
            item-text="Name"
            item-value="DashboardId"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updatePDF()">
            {{ $t('Accept') }}
          </v-btn>
          <v-btn @click="showPdfPopup = false">
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <sensor-selector
      v-if="showSensorSelectorDialog"
      v-model="showSensorSelectorDialog"
      :show-sensors="showSensors"
      :show-vectors="showVectors"
      :hide-y2="true"
      @sensors-added="addSensors($event)"
    />
    <AlertAckPopup
      v-model="popupack.Show"
      :model="popupack.Model"
      :schema="popupack.Schema"
      :title="popupack.Title"
      @on-accept="onAckPopupAccept"
    />
    <v-dialog
      v-model="renameSensorsDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Alert.Manage.AlertList.MassRename') }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newName"
            :label="$t('Alert.Manage.AlertList.NewName')"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn @click="renameSensors()">
            {{ $t('Accept') }}
          </v-btn>
          <v-btn @click="renameSensorsDialog = false">
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="showDeletePopup"
      :title="$t('ConfirmDeletion')"
      @on-accept="deleteMultipleAlerts()"
    />
    <v-dialog
      v-model="deleteConfirmationDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Warning') }}
        </v-card-title>
        <v-card-text>
          {{ $t('Alert.Manage.AlertList.NotOkToDelete') }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteConfirmationDialog = false">
            {{ $t('Accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import GsAlert from './gridsources/gssensors';
import SensorSelector from '@/dashboard/components/SensorSelector.vue';
import AlertAckPopup from '../AlertAckPopup.vue';
import ConfirmDialog from '../../../components/ConfirmDialog.vue';
import AutoInfoPopup from '@/components/AutoInfoPopup.vue';
import SubscribeSetting from '../../../dashboard/components/subscription/SubscribeSetting.vue';
import importal from '@/api/importal';

function NotNull(x) {
  return x !== null && x !== undefined;
}

export default {
  name: 'AlertEdit',
  components: {
    ServerSideDataGrid,
    AlertAckPopup,
    SensorSelector,
    ConfirmDialog,
    AutoInfoPopup,
    SubscribeSetting,
  },
  data() {
    return {
      sources: {
        GsAlert: null,
      },
      refresh: 0,
      toolbarButtons: [],
      selectedRows: [],
      selectedAlert: null,
      rowSensors: null,
      showSensorSelectorDialog: null,
      showSensors: true,
      showVectors: false,
      popupinfo: {
        Show: false,
        Model: {},
        Title: '',
      },
      popupack: {
        Show: false,
        Model: {},
        Schema: {},
        Title: '',
      },
      Info: {
        Name: null,
        Type: null,
        TypeInfo: null,
        Subtitle: null,
        SecondNomTypeInfo: null,
        SecondNomSubtitle: null,
        SensorName: null,
        InstrumentName: null,
        WeekDays: null,
      },
      showEditPopup: false,
      newName: '',
      DayAbbrList: [
        'Alert.Manage.AlertList.Sunday',
        'Alert.Manage.AlertList.Monday',
        'Alert.Manage.AlertList.Tuesday',
        'Alert.Manage.AlertList.Wednesday',
        'Alert.Manage.AlertList.Thursday',
        'Alert.Manage.AlertList.Friday',
        'Alert.Manage.AlertList.Saturday',
      ],
      Scale: [{ Name: 'Months', Scale: 43800 }, { Name: 'Weeks', Scale: 10080 }, { Name: 'Days', Scale: 1440 }, { Name: 'Hours', Scale: 60 }, { Name: 'Minutes', Scale: 1 }],
      showDeletePopup: false,
      deleteConfirmationDialog: false,
      renameSensorsDialog: false,
      showPdfPopup: false,
      selectedDashboardId: null,
    };
  },
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
    ...mapState('alert', ['rowdata']),
    ...mapState('user', ['identity']),
    ...mapState('subscribe', ['dashboardsWNone']),
    ...mapState('dashboard', ['dashboards']),

    setButtonEnabled() {
      return this.selectedRows.length > 0;
    },
  },
  watch: {
    async tenantId() {
      this.onRefresh();
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.onRefresh();
      }
    },
    async dashboards() {
      this.$store.dispatch('subscribe/initializeDashboards', this.dashboards);
      this.onRefresh();
    },
  },
  async created() {
    this.sources.GsAlert = new GsAlert(this, {
      onSeeInfo: (data) => this.onSeeInfo(data),
      onSeeJournal: (data) => this.onSeeJournal(data),
      onEdit: (data) => this.onEdit(data),
    });
    await this.$store.dispatch('dashboard/list');
    this.$store.dispatch('subscribe/initializeDashboards', this.dashboards);
    const alertId = this.$router.currentRoute.params.id;
    await this.$store.dispatch('alert/listSensorsAlert', {
      Id: alertId,
    });
    this.rowSensors = this.rowdata;
    this.setTitle();
  },
  mounted() {
    this.toolbarButtons = [
      {
        tooltip: this.$t('Back'),
        icon: 'mdi-arrow-left',
        enabled: true,
        click: () => { this.onBack(); },
      },
      {
        tooltip: this.$t('Refresh'),
        icon: 'mdi-refresh',
        enabled: true,
        click: () => { this.onRefresh(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.Toggle'),
        icon: 'mdi-bell',
        enabled: this.setButtonEnabled,
        click: () => { this.onMuteAlerts(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.AckIcon'),
        icon: 'mdi-alert-minus',
        enabled: this.setButtonEnabled,
        click: () => { this.showAckPopup(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.RenameSensors'),
        icon: 'mdi-rename-box',
        enabled: this.setButtonEnabled,
        click: () => { this.showRenamePopup(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.PdfIcon'),
        icon: 'mdi-file-pdf-box',
        enabled: this.setButtonEnabled,
        click: () => { this.showPdfSelection(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.Delete'),
        icon: 'mdi-delete',
        enabled: this.setButtonEnabled,
        click: () => { this.onDeleteAlerts(); },
      },
    ];
  },
  methods: {
    async updatePDF() {
      let basicAlertId = null;
      const selectedIds = [];

      this.selectedRows.forEach((alert) => {
        selectedIds.push(alert.SensorId);
        if (!basicAlertId) {
          basicAlertId = alert.BasicAlertConfigId;
        }
      });

      if (this.selectedDashboardId === this.$t('(None)')) {
        await importal.post('AlertEmailSettingsUpdate', {
          AlertId: basicAlertId,
          SensorAlertIds: selectedIds,
          DashId: undefined,
        });
      } else {
        await importal.post('AlertEmailSettingsUpdate', {
          AlertId: basicAlertId,
          SensorAlertIds: selectedIds,
          DashId: this.selectedDashboardId,
        });
      }
      this.showPdfPopup = false;
      this.onRefresh();
    },
    setTitle() {
      this.$store.dispatch('app/changePageTitle', {
        key: this.$t('Alert.Manage.SensorPageTitle').concat(' ').concat(this.rowSensors[0].LongName),
      });
    },
    onSeeInfo(data) {
      this.seeInfo(data);

      this.popupinfo.Title = this.Info.Name;
      this.popupinfo.Model = {
        [this.$t('Alert.Manage.AlertList.InstrumentName')]: this.Info.InstrumentName,
        [this.$t('Alert.Manage.AlertList.SensorName')]: this.Info.SensorName,
        Type: this.Info.Type,
        [this.Info.Subtitle]: this.Info.TypeInfo,
      };
      if (this.Info.SecondNomSubtitle) {
        this.popupinfo.Model[this.Info.SecondNomSubtitle] = this.Info.SecondNomTypeInfo;
      }
      this.popupinfo.Model[this.$t('Alert.Manage.AlertList.ActiveDays')] = this.Info.WeekDays;
      this.popupinfo.Show = true;
    },
    onEditAlert(alert) {
      if (alert) {
        this.$store.dispatch('alert/refreshEditSensor', alert);
      }
      this.showEditPopup = false;
    },
    seeInfo(alert) {
      this.Info = {
        Name: null,
        Type: null,
        TypeInfo: null,
        Subtitle: null,
        SecondNomTypeInfo: null,
        SecondNomSubtitle: null,
        SensorName: null,
        InstrumentName: null,
        WeekDays: null,
      };

      this.Info.Name = alert.Name;
      this.Info.InstrumentName = alert.InstrumentName;
      this.Info.SensorName = alert.SensorName;
      if (alert.IsScheduled) {
        this.Info.Type = this.$t('Alert.Manage.AlertList.Scheduled');
        this.Info.Subtitle = this.$t('Alert.Manage.AlertList.Every');
        this.Info.TypeInfo = this.getEditFrequency(alert);
      } else if (alert.ValueDelayHours != null) {
        this.Info.Type = this.$t('Alert.Manage.AlertList.MissingValue');
        this.Info.Subtitle = this.$t('Alert.Manage.AlertList.MissingFor');
        this.Info.TypeInfo = this.$t(this.getEditMissingCheck(alert));
      } else {
        this.Info.Type = this.$t('Alert.Manage.AlertList.Nominality');
        if (NotNull(alert.ValueLt)) {
          this.Info.Subtitle = this.$t('Alert.Manage.AlertList.ValueLessThan');
          this.Info.TypeInfo = String(alert.ValueLt);
        } if (NotNull(alert.ValueLteq)) {
          this.Info.Subtitle = this.$t('Alert.Manage.AlertList.ValueLessThanOrEqualTo');
          this.Info.TypeInfo = String(alert.ValueLteq);
        } if (NotNull(alert.ValueGt)) {
          if (this.Info.TypeInfo == null) {
            this.Info.Subtitle = this.$t('Alert.Manage.AlertList.ValueGreaterThan');
            this.Info.TypeInfo = String(alert.ValueGt);
          } else {
            this.Info.SecondNomTypeInfo = String(alert.ValueGt);
            this.Info.SecondNomSubtitle = this.$t('Alert.Manage.AlertList.AndValueGreaterThan');
          }
        } if (NotNull(alert.ValueGteq)) {
          if (this.Info.TypeInfo == null) {
            this.Info.Subtitle = this.$t('Alert.Manage.AlertList.ValueGreaterThanOrEqualTo');
            this.Info.TypeInfo = String(alert.ValueGteq);
          } else {
            this.Info.SecondNomTypeInfo = String(alert.ValueGteq);
            this.Info.SecondNomSubtitle = this.$t('Alert.Manage.AlertList.AndValueGreaterThanOrEqualTo');
          }
        }
      }

      let daysString = '';
      for (let i = 0; i < 7; i += 1) {
        // eslint-disable-next-line no-bitwise
        if (alert.ValidationDays & (1 << i)) {
          if (i > 0 && daysString !== '') {
            daysString += ', ';
          }
          daysString += this.$t(this.DayAbbrList[i]);
        }
      }

      this.Info.WeekDays = daysString;
    },
    onEdit(data) {
      this.selectedAlert = data;
      this.showEditPopup = true;
    },
    isNominal(item) {
      if (
        NotNull(item.ValueLt)
        || NotNull(item.ValueLteq)
        || NotNull(item.ValueGt)
        || NotNull(item.ValueGteq)
      ) {
        return true;
      }
      return false;
    },
    async onSeeJournal(data) {
      this.$router.push({
        name: 'Alert.Status.Alert',
        params: {
          sensorId: data.SensorId,
          alertId: data.BasicAlertConfigId,
        },
      });
    },
    showPdfSelection() {
      this.showPdfPopup = true;
    },
    closePdfSelection(alert) {
      if (alert) {
        alert.forEach((row) => {
          this.$store.dispatch('alert/refreshEditSensor', row);
        });
      }
      this.showPdfPopup = false;
    },
    onSelect(e) {
      const { api } = e;
      this.selectedRows = api.getSelectedRows();
      [1, 2, 3, 4, 5, 6].forEach((x) => {
        this.toolbarButtons[x].enabled = this.setButtonEnabled;
      });
    },
    onDeleteAlerts() {
      this.showDeletePopup = true;
    },
    async deleteMultipleAlerts() {
      const selectedIds = [];
      let basicAlertId = null;

      this.selectedRows.forEach((alert) => {
        selectedIds.push(alert.SensorId);
        if (!basicAlertId) {
          basicAlertId = alert.BasicAlertConfigId;
        }
      });

      await this.$store.dispatch('alert/delete', {
        BasicAlertConfigId: basicAlertId,
        SensorIds: selectedIds,
      });
    },
    async deleteAlert() {
      await this.$store.dispatch('alert/delete', {
        BasicAlertConfigId: this.selectedAlert.BasicAlertConfigId,
        SensorId: this.selectedAlert.SensorId,
      });
      await this.onRefresh();
    },
    onBack() {
      this.$router.push('/alert/manage');
    },
    async onRefresh() {
      this.refresh += 1;
      this.selectedRows = [];
      const alertId = this.$router.currentRoute.params.id;
      await this.$store.dispatch('alert/listSensorsAlert', {
        Id: alertId,
      });
      this.rowSensors = this.rowdata;
      [2, 3, 4, 5, 6].forEach((x) => {
        this.toolbarButtons[x].enabled = this.setButtonEnabled;
      });
    },
    onMuteAlerts() {
      this.selectedRows.forEach((alert) => {
        this.muteAlert(alert);
      });
    },
    isMute(item) {
    // eslint-disable-next-line no-bitwise
      if (item.StateFlag & 0x0004) {
        return true;
      }
      return false;
    },
    async muteAlert(item) {
      if (item.NotificationPriority < 75 || this.isMute(item)) {
        await this.$store.dispatch('alert/mute', {
          BasicAlertConfigId: item.BasicAlertConfigId,
          StateFlag: item.StateFlag,
          StateName: 'muted',
          UserId: this.identity.user.UserId,
          SensorId: item.SensorId,
        });
      } else {
        this.deleteConfirmationDialog = true;
      }
    },
    showAckPopup() {
      this.popupack.Title = this.$t('Alert.Manage.AlertList.AckTitle');
      this.popupack.Model = {
        Message: null,
      };
      this.popupack.Schema = {
        Message: {
          label: this.$t('Alert.Manage.AlertList.AckMessage'),
          placeholder: this.$t('Alert.Manage.AlertList.WriteMessageOptional'),
          type: 'text',
          flex: 12,
        },
      };
      this.popupack.Show = true;
    },
    showRenamePopup() {
      this.renameSensorsDialog = true;
    },
    async renameSensors() {
      const sensorIds = this.selectedRows.map((alert) => alert.SensorId);

      await importal.post('AlertSensorRename', {
        BasicAlertConfigId: this.selectedRows[0].BasicAlertConfigId,
        SensorId: sensorIds,
        Name: this.newName,
      });

      // Close the dialog and refresh the data
      this.renameSensorsDialog = false;
      this.onRefresh();
    },
    onAckPopupAccept(msg) {
      this.selectedRows.forEach((alert) => {
        this.selectedAlert = alert;
        this.acceptAck(msg);
      });
    },
    async acceptAck(msg) {
      await this.$store.dispatch('alert/ack', {
        BasicAlertConfigId: this.selectedAlert.BasicAlertConfigId,
        StateFlag: this.selectedAlert.StateFlag,
        StateName: 'acked',
        Message: JSON.stringify(msg),
        UserId: this.identity.user.UserId,
        SensorId: this.selectedAlert.SensorId,
      });
    },
    getEditMissingCheck(alert) {
      const frequencyHours = alert.ValueDelayHours;
      for (let i = 0; i < this.Scale.length; i += 1) {
        if (frequencyHours % (this.Scale[i].Scale / 60) === 0) {
          const Scaling = this.Scale[i].Name;
          const ScalingTranslate = 'Alert.Manage.AlertList.'.concat(Scaling);
          const Value = frequencyHours / (this.Scale[i].Scale / 60);
          const ReturnValue = `${Value} ${this.$t(ScalingTranslate)}`;
          return ReturnValue;
        }
      }
      return `${frequencyHours} ${this.$t('Alert.Manage.AlertList.Hours')}`;
    },
    getEditFrequency(alert) {
      const frequencyHours = alert.ValueDelayHours;
      for (let i = 0; i < this.Scale.length; i += 1) {
        if (frequencyHours % (this.Scale[i].Scale / 60) === 0) {
          const Scaling = this.Scale[i].Name;
          const ScalingTranslate = 'Alert.Manage.AlertList.'.concat(Scaling);
          const Value = frequencyHours / (this.Scale[i].Scale / 60);
          const ReturnValue = `${Value} ${this.$t(ScalingTranslate)}`;
          return ReturnValue;
        }
      }
      return `${frequencyHours} ${this.$t('Alert.Manage.AlertList.Hours')}`;
    },
    addNewSensor() {
      this.showSensorSelectorDialog = true;
    },
    addSensors(array) {
      array.forEach(async (sensor) => {
        if (this.rowSensors.length > 0) {
          const existingSensor = this.rowSensors.find((s) => s.SensorId === sensor.Id);

          if (!existingSensor) {
            await this.$store.dispatch('subscribe/add', {
              BasicAlertConfigId: Number(this.$router.currentRoute.params.id),
              InstrumentName: sensor.InstrumentName,
              SensorName: sensor.DisplayName,
              SensorId: sensor.Id,
              InstrumentId: sensor.InstrumentId,
            })
              .catch((err) => {
                if (err.response.data.exception) {
                  this.showError(err.response.data.exception);
                }
              });
            this.onRefresh();
          }
        } else {
          await this.$store.dispatch('subscribe/add', {
            BasicAlertConfigId: Number(this.$router.currentRoute.params.id),
            InstrumentName: sensor.InstrumentName,
            SensorName: sensor.DisplayName,
            SensorId: sensor.Id,
            InstrumentId: sensor.InstrumentId,
          })
            .catch((err) => {
              if (err.response.data.exception) {
                this.showError(err.response.data.exception);
              }
            });
          this.onRefresh();
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>
